<template>
  <div class="ve-tree" style="height:calc(100vh - 1.30208vw)">
  <!-- 不使用虚拟滚动时只需去掉height参数即可 -->
    <vue-easy-tree
      ref="veTree"
      node-key="id"
      show-checkbox
      height="6.51042vw"
      :data="treeData"
      :props="props"
    ></vue-easy-tree>
  </div>
</template>
 
<script>
import VueEasyTree from "@wchbrad/vue-easy-tree";
 
export default {
  data() {
    return {
      props: {
        label: "name",
        children: "children"
      },
      treeData: []
    };
  },
    
  components: {
    VueEasyTree
  },
 
  created() {
    const data = [],
      root = 8,
      children = 3,
      base = 1000;
    for (let i = 0; i < root; i++) {
      data.push({
        id: `${i}`,
        name: `test-${i}`,
        children: []
      });
      for (let j = 0; j < children; j++) {
        data[i].children.push({
          id: `${i}-${j}`,
          name: `test-${i}-${j}`,
          children: []
        });
        for (let k = 0; k < base; k++) {
          data[i].children[j].children.push({
            id: `${i}-${j}-${k}`,
            name: `test-${i}-${j}-${k}`
          });
        }
      }
    }
    this.treeData = data;
  }
};
</script>