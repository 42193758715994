<template>

    <div class="box">
        <div class="conter">
            <div>
                IP:{{ IP }}
                城市：{{ city }}
            </div>
            <div>
                用户手机品牌:{{ mobilePhoneName }}

                用户手机型号：{{modelNumber}}

            </div>
            <div>


                浏览器内核：{{ browserCentre }}
                运行设备：{{ PC }}
            </div>

        </div>
        <div class="conter"> 
                {{projectName}}

        </div>

    </div>

</template>

<script>
import DeviceDetector from "device-detector-js";
export default {
    data() {
        return {
            //用户ip
            IP: "",
            // 城市
            city: "",


            // 用户手机名
            mobilePhoneName: "",
            //用户手机型号
            modelNumber:"",

            //浏览器内核
            browserCentre: "",
            //pc还是手机端
            PC: "",
            projectName:"项目名称",


        }
    },
    mounted() {

        //获取用户ip
        this.ready();
        //获取用户设备信息
        this.mobileInfoInit()


        this.IsPC()

    },
    methods: {


        ready() {
            var cip = returnCitySN["cip"];
            this.IP = cip;
            this.city = returnCitySN["cname"];
            console.log(returnCitySN, "returnCitySN");

        },
        mobileInfoInit() {
            // 设备信息
            const deviceDetector = new DeviceDetector();
            const userAgent = window.navigator.userAgent;
            let device = deviceDetector.parse(userAgent);
            console.log(device, userAgent)
            this.browserCentre = device.client.name
        },


        IsPC() {
            var is_mobi = navigator.userAgent.toLowerCase().match(/(ipod|ipad|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i) != null;
            if (!is_mobi) {
                console.log("pc");
                this.PC="PC"
            } else {

                var sUserAgent=navigator.userAgent.toLowerCase()
                var isIphone = sUserAgent.match(/iphone/i) == "iphone";
                var isHuawei = sUserAgent.match(/huawei/i) == "huawei";
                var isHonor = sUserAgent.match(/honor/i) == "honor";
                var isOppo = sUserAgent.match(/oppo/i) == "oppo";
                var isOppoR15 = sUserAgent.match(/pacm00/i) == "pacm00";
                var isVivo = sUserAgent.match(/vivo/i) == "vivo";
                var isXiaomi = sUserAgent.match(/mi\s/i) == "mi ";
                var isXiaomi2s = sUserAgent.match(/mix\s/i) == "mix ";
                var isRedmi = sUserAgent.match(/redmi/i) == "redmi";
                var isSamsung = sUserAgent.match(/sm-/i) == "sm-";








                var ua = navigator.userAgent.toLowerCase();
                if (/android|adr/gi.test(ua)) {
                    // 安卓
                    console.log("这是安卓");
                    this.PC="安卓"
                    let m1 = navigator.userAgent.match(/Android.*; ?(.*(?= Build))/)
                        if (m1 && m1.length > 1) {
                        // device = m1[1]    // MI 9
                        this.modelNumber=m1[1]  
                    }

                    

                        
                    if (isIphone) {
                        this.mobilePhoneName= 'iphone';
                    } else if (isHuawei || isHonor) {
                        this.mobilePhoneName=  'huawei';
                    } else if (isOppo || isOppoR15) {
                        this.mobilePhoneName=  'oppo';
                    } else if (isVivo) {
                        this.mobilePhoneName=  'vivo';
                    } else if (isXiaomi || isRedmi || isXiaomi2s) {
                        this.mobilePhoneName=  'xiaomi';
                    } else if (isSamsung) {
                        this.mobilePhoneName=  'samsung';
                    } else {
                        this.mobilePhoneName=  'default';
                    }
                







                } else if (/\(i[^;]+;( U;)? CPU.+Mac OS X/gi.test(ua)) {
                    //苹果
                    console.log("苹果");
                this.PC="苹果"
                    
                } else if (/iPad/gi.test(ua)) {
                    //ipad
                    console.log("ipad");
                this.PC="ipad"


                }else{
                this.PC="其他"

                }
            }




        }



    },


}
</script>

<style>
.conter {
    width: 500px;
    height: 200px;
    box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
    margin: 0 auto;
    margin-top: 30px;
    background-color: #fff;
}
</style>