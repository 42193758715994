<template>
    <div class="box">
        <div class="title">基础信息</div>
        <div class="content">
            <div class="uploading"  @click="btnChange()" >
                <input type="file" value="选择图片" ref="file" @change="fileChange($event)" accept="image/png, image/jpeg, image/jpg" style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0"/>
                
                <img v-if="!filestate" :src="headPortrait1" alt="" >


                

                <i class="el-icon-plus" v-if="filestate"></i>
                <!-- <div class="upload" v-if="filestate">



                 <img src="../../../assets/Login/file.png" alt="">
                
                </div> -->
            </div>
            <div class="basics">
               
               <div class="basicsItem1">

                <span class="labe"> 项目名称：</span>
                
                <el-input v-model="NameShop" placeholder="请输入内容"></el-input>
               </div>
               <div class="basicsItem1">
                <span class="labe">
                    项目简介：

                </span>
                <el-input
                    type="textarea"
                    :rows="5"
                    resize='none' 
                    placeholder="请输入内容"
                    v-model="briefIntroduction">
                    </el-input>

               </div>
            </div>
        </div>
        <div class="switch">

            <div class="item">互动功能： 
                <el-switch v-model="interaction">
                </el-switch>
            </div>
            <div class="item">地图展示： 
                <el-switch v-model="map">
                </el-switch>
            </div>
            <div class="item">店铺统计： 
                <el-switch v-model="StoreStatistics">
                </el-switch>
            </div>
            <div class="item">店铺人数： 
                <el-switch v-model="NumberShops">
                </el-switch>
            </div>
     
        </div>

    </div>

</template>

<script>

import { OssManager } from "../../../threejs/network/OssManager";


 import {MainObjects} from "../../../threejs/common/MainObjects";

import {mapState} from 'vuex'
export default {
    data() {
        return {
            
            NameShop:"",
            interaction:false,
            map:false,
            StoreStatistics:false,
            NumberShops:false,
            // img地址
            headPortrait1:"",
            filestate:true,
            briefIntroduction:"",
            reNum:""
        }
    },
    computed: { 


},
mounted(){
    this.reNum = this.getNum(20); //生成图片时 造随机数
},
methods:{
    getNum(num) {
      var random = Math.floor(
        (Math.random() + Math.floor(Math.random() * 9 + 1)) *
          Math.pow(10, num - 1)
      );
      return random;
    },
    fileChange(e){
        var _this = this;
        
      let https = MainObjects.Network.m_http;

    //   let getHtttps=MainObjects.Network.m_http;
      
      const files = e.target.files
      console.log(files,"files")

      let data = new OssManager(MainObjects.Network.m_bucketName);

      let url =   data.getCloudShopFloder("basicInformation", this.reNum)

     





      function uploadFinish(list) {
        let urls = https + url + files[0].name;
        _this.headPortrait1 = urls;
        _this.filestate = false;
      }

      data.f_finishCallback = uploadFinish;
  
      data.upload(files[0],url)
    },

    btnChange(){
      this.$refs.file.click()
    },
}

}
</script>

<style lang="less" scoped>
    .box{
        width: 90%;
        height: 100%;
        margin: 0 auto;
        .title{
            width: 100%;
            height: 50px;
            font-size: 20px;
            margin-top: 20px;
            color: #000;
            font-weight: bold;

        }
        .content{
            display: flex;
            justify-content: start;
           .uploading{
            width: 200px;
            height: 200px;
            background-color: #fff;
            box-shadow: 0px 0px 6px 0px rgba(33,35,49,0.1);
            position: relative;
            i{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
            img{
                width: 100%;
                height: 100%;
            }

           }
           .basics{
            margin-left: 30px;
            .basicsItem1{
                margin-bottom: 20px;
                width: 300px;
                display: flex;
                justify-content: start;
                .labe{
                    width: 120px;
                }
            }
           }
        }
        .switch{
            margin-top: 30px;
            display: flex;
            .item{
                width: 150px;
                height: 30px;
                
                box-shadow: 0px 0px 6px 0px rgba(33,35,49,0.1);
                line-height: 30px;
                margin-right: 30px;
                text-align: center;
            }
        }
    }
</style> 