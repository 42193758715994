<template>
    <div>
        <div class="directoryPath">
            <div class="path">
                <div class="pathItem">
                    <span>
                        全部文件
                    </span>
                    <!-- <span>|</span> -->
                </div>
                <div class="pathItem" v-for=" item in catalogueList" :key="item.id">
                    <span>|</span>

                    <span>
                        {{ item.name }}
                    </span>
                </div>
                <!-- <div class="nowPath">
              
                        </div> -->

            </div>
        </div>
        <div class="tree" v-loading="loading">
            <!-- 选择本地文件夹 -->
            <vue-easy-tree empty-text="没找到目录" ref="tree" :height="500" :data="dataList" :show-checkbox="true" node-key="id"
                :expand-on-click-node="false" :highlight-current="true" :check-strictly="true"
                @check-change="groupHandleClick" :props="groupTreeDefaultProps">

                <!-- 默认展开节点 -->
                <!-- class="custom-tree-node"
            :class="{'custom-tree-node':true}" -->
                <span slot-scope="{ node, data }">
                    <!-- 非编辑状态 -->
                    <span class="custom-tree-node-content">
                        <el-tooltip class="item" effect="dark" :content="data.name" placement="top">

                            <span class="tree_label">
                                {{ data.name }}
                            </span>
                        </el-tooltip>
                    </span>
                </span>
            </vue-easy-tree>

        </div>


        <div slot="footer" class="dialog-footer">
            <!--  -->
            <!-- 移動 -->
            <el-button v-if="$store.state.cloudTreeOperationType == '1'" type="primary" @click="move()">移动到此</el-button>



            <el-button v-else-if="$store.state.cloudTreeOperationType == '2'" type="primary"
                @click="duplication()">复制到此</el-button>


            <el-button v-else-if="$store.state.cloudTreeOperationType == '3'" type="primary"
                @click="saveStorefront()">保存</el-button>
            <el-button type="primary" @click="newfolderIsShow()">新建文件夹</el-button>

            <div style="marginTop:1.30208vw;" v-show="newfolderShow">
                请输入文件夹名字:
                <input v-model="folderName" type="text" class="name" />
                <el-button style="marginLeft:0.65104vw" type="primary" @click="newfolder()">确定</el-button>
            </div>




        </div>
    </div>
</template>

<script>
//导入组件树
import VueEasyTree from "@wchbrad/vue-easy-tree";
import { INetwork } from "../../../threejs/interface/INetwork";

import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
    name: "SelectFolder",
    data() {
        return {
            //树的数据
            dataList: [],
            //树配置
            groupTreeDefaultProps: {
                children: "subCatalog",
                label: "name",
                object: "object",

            },
            //判断树是否有被选择
            isChecked: false,
            loading: false,
            folderName: "",
            newfolderShow: false,
            catalogueList: [],

      
        }
    },
    watch: {
        "folderName": {
            handler(newV, oldV) {
                // console.log(this.ruleForm.imageName, "ruleForm.imageName变化前");

                this.folderName = this.folderName.replace(
                    /[^\w\u4E00-\u9FA5]/g,
                    ""
                );
                // console.log("watch", newV, oldV);

                // console.log(this.ruleForm.imageName, "ruleForm.imageName变化后");
            },
            immediate: true
        },

    },
    computed: {
        ...mapState(["cloudFileData", "cloudTreeOperationType"]),
    },

    components: {
        VueEasyTree
    },
    props: ["parentId","groupId"],



    mounted() {
        this.loading = true
        this.getFolderList()


        this.catalogueList = []

    },
    methods: {

        ...mapMutations(["changeCloudFileData", "changeCloudTree", "changeCloudTreeOperationType"]),


        async folderPath(id) {
            const res = await this.$https.folderPath({ folderId: id })

            if (res.success == true) {
                this.catalogueList = res.data
                console.log(this.catalogueList, " this.catalogueList");
            }
        },


        newfolderIsShow() {
            this.newfolderShow = true
        },
        // 新建文件夹
        newfolder() {
            let folderId = this.$refs.tree.getCheckedNodes()[0] ? this.$refs.tree.getCheckedNodes()[0].id : ""


            let isTrue = this.folderName.split(" ").join("").length == 0
            if (isTrue) {

                this.$message({
                    // type: "info",
                    message: "请填写项目名称"
                });
                return false

            } else {
                this.addCloudStoreFolder(this.folderName, folderId)

            }


        },

        //新建文件夹
        async addCloudStoreFolder(name, parent) {
            let data = {
                name: name,
                parent: parent,
                groupId:this.groupId

            }
            const res = await this.$https.addCloudStoreFolder(data)
            if (res.success == true) {


                this.$message({
                    message: "创建成功"

                });
                this.getFolderList()
                this.folderName = "",
                    this.newfolderShow = false
            } else {
                this.$message({
                    message: res.errMessage

                });


            }
        },


        // 新建云店
        async saveStorefront() {
            let _this=this


            let idList = this.$refs.tree.getCheckedNodes()
            let folderId = idList[0].id

            let data = this.$store.state.cloudData

            data.folderId = folderId

            const res = await this.$https.addSceneryCloudStore(data)

            if (res.success == true) {
                this.changeCloudTree(false)
                // this.$router.push({ path: '/PlugIn/CloudStores/FileDirectory', })

                console.log(this.groupId,"this.groupId");
                this.$router.push({ path: '/PlugIn/CealisticCloudStores/compileDirectory', query: { parentId: res.data,groupId:this.groupId } })


                console.log( _this.$parent.$parent.ICloudSoresThree," _this.$parent.ICloudSoresThree")
                
                // _this.$parent.$parent.addStoreModel()
                
                // _this.$parent.$parent.ICloudSoresThree.changeSqlId(res.data)
                // _this.$parent.$parent.ICloudSoresThree.save(() => {
                //     console.log("上穿成功");
                //     _this.$message({
                //         // type: "info",
                //         message: "保存成功请去对应目录查看"
                //     });
                //     // location.reload()

                // })



            } else {

                this.$message({
                    // type: "info",
                    message: res.errMessage
                });
            }



        },

        //选择文件夹
        selectFolder() {
            let idList = this.$refs.tree.getCheckedNodes()

            if (idList.length == 0) {
                this.$message({
                    // type: "info",
                    message: "请至少选择一个文件夹"
                });
            } else {

                this.$store.commit("changeSelectFolderId", idList[0].id)
                this.$store.commit("changeDownloadShow", false)

            }

        },
        async copyCloudFolderFile(id, type, folderId, name) {
            // 复制

            let data = {
                id: id,
                type: type,
                folderId: folderId,
                name: name
            }
            const res = await this.$https.copyCloudFolderFile(data)
            if (res.success == true) {
                if (res.data) {
                    this.setUrl(res.data)

                } else {





                    this.$parent.$parent.cloudStoreList(this.parentId)

                }



            } else {

                if (res.errCode == 410) {

                    let _this = this
                    var containSpecial = RegExp(
                        /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
                    );

                    this.$prompt(``, "检测到重名请输入新名字", {
                        confirmButtonText: "确定",
                        showClose: true,
                        // closeOnPressEscape: false,
                        // closeOnClickModal: false,
                        // center: true,

                        inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",
                        inputValidator: value => {
                            // 点击按钮时，对文本框里面的值进行验证
                            if (!value) {
                                return "输入不能为空";
                            } else if (containSpecial.test(value)) {
                                return "输入不能为特殊字符";
                            } else if (value.length > 20) {
                                return "输入文字不能超过20个字符";
                            }
                        }
                    }).then(({ value }) => {




                        _this.copyCloudFolderFile(data.id, data.type, data.folderId, value).then(() => {
                            _this.changeCloudTree(false)
                        })



                    }).catch(() => {


                        this.$message({
                            // type: "info",
                            message: "已取消"
                        });
                    });


                } else {
                    this.$message({
                        message: res.errMessage
                        // type: "warning"
                    });
                }


            }

        },
        //复制url
        setUrl(list) {
            let urlList = []
            if (list) {
                list.forEach(element => {
                    let data = {
                        id: element.id
                    }
                    data.deputyUrl = INetwork.copyPaseFilesToSql(
                        element.deputyUrl.split(","),
                        element.id,
                        () => { }
                    ).toString();
                    data.fileUrl = INetwork.copyPaseFilesToSql(

                        element.fileUrl.split(","),
                        element.id,

                        () => { }
                    )[0];

                    urlList.push(data)

                });

                this.updateStoreUrl(urlList)
            }

        },

        //服务器复制url
        async updateStoreUrl(storeListCmd) {
            let data = {
                storeList: storeListCmd
            }
            const res = await this.$https.updateStoreUrl(data)
            if (res.success == true) {
                this.$message({
                    // type: "info",
                    message: "复制成功"
                });
                this.$parent.$parent.cloudStoreList(this.parentId)
            } else {
                this.$message({
                    // type: "info",
                    message: res.errMessage
                });
            }

        },



        //复制
        duplication() {
            let folderId = this.$refs.tree.getCheckedNodes()[0].id

            this.copyCloudFolderFile(this.cloudFileData.id, this.cloudFileData.type, folderId, "").then(() => {
                this.changeCloudTree(false)
            })
        },


        //文件类型的同步
        fileDownload() {

            let idList = this.$refs.tree.getCheckedNodes()

            if (idList.length == 0) {
                this.$message({
                    // type: "info",
                    message: "请至少选择一个文件夹"
                });
            } else {



                //所选文件夹id
                let folderId = this.$refs.tree.getCheckedNodes()[0].id

                let node = this.$store.state.cloudFileData

                var time = new Date().getTime();
                let data = {

                }

                let imageUrl = [];
                imageUrl.push(node.imageUrl.split("?")[0]);
                data.id = node.id

                data.deputyUrl = INetwork.copyPaseFilesToSql(
                    node.deputyUrl.split(","),
                    node.id,
                    () => { }
                ).toString();
                data.fileUrl = INetwork.copyPaseFilesToSql(
                    node.fileUrl.split(","),
                    node.id,

                    () => { }
                )[0];
                data.imageUrl = INetwork.copyPaseFilesToSql(
                    imageUrl,
                    node.id,
                    () => { }
                )[0] + "?" + "time=" + time;


                this.addFile(folderId, node.name, node.type, data.fileUrl, node.fileSize, data.imageUrl, data.deputyUrl).then(
                    () => {
                        this.$store.commit("changeDownloadShow", false)

                    }
                )



            }

        },

        //调用后台接口 添加文件
        async addFile(folderId, name, type, fileUrl, fileSize, imageUrl, deputyUrl) {
            // let fileName = this.mainUrl.split("/");
            // let name = fileName[fileName.length - 1];
            // name = name.split("?")[0];


            const res = await this.$https.addFile({
                //    this.url_list=url_list
                // this.mainUrl=mainUrl
                //文件夹id
                // folderId:this.id,
                projectId: localStorage.getItem("projectId"),
                folderId: folderId,
                // name: this.Node.name,
                name: name,
                type: type,
                fileUrl: fileUrl,
                fileSize: fileSize,
                imageUrl: imageUrl,
                deputyUrl: deputyUrl
            });

            if (res.success == true) {


                this.$message({
                    message: '同步成功'
                    // type: "warning"
                });
                // 上传成功
                // this.fileId = res.data;


                // INetwork.getFileList(this.fileId, () => {

                // //   this.updateFile().then(() => {
                // //     // this.$emit("setFileDirectoryId", this.FileDirectoryId);
                // //   });
                // });
                // .catch(()=>{
                //     this.$emit("setFileDirectoryId", this.FileDirectoryId);

                // })


            } else {
                var token = localStorage.getItem("token");
                if (token) {
                    this.$message({
                        message: res.errMessage
                        // type: "warning"
                    });
                }
            }
        },
        // setFileId(msg) {

        //   this.fileId = msg;
        // },

        //调用后台接口 更改文件
        async updateFile() {
            var fileId;
            // var updateFileId = localStorage.getItem("updateFileId");
            // if (
            //   updateFileId !== undefined &&
            //   updateFileId !== null &&
            //   updateFileId !== ""
            // ) {
            //   fileId = updateFileId;  
            // } else {
            fileId = this.fileId;
            // }

            // var name;
            // if (this.upgrading == 1) {
            //   name = this.fileName;
            // } else if (this.upgrading == 2) {
            //   name = this.upDataNode.name;
            // }


            //获取真实的数据 这里需要传文件类型

            var data = INetwork.getGroupJsonToSql(fileId, () => { });

            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            });

            var time = new Date().getTime();

            const res = await this.$https.updateFile({
                //文件夹id
                // folderId:this.id,
                projectId: localStorage.getItem("projectId"),
                id: fileId,
                // name: name,
                fileUrl: data.mainName,
                imageUrl: data.imageName + "?" + "time=" + time,
                deputyUrl: data.otherNameList.toString(),
                fileSize: this.totalSize
            });

            if (res.errCode == null) {
                IResousePreview.close();
                loading.close();
                this.fileName = "";

                this.$parent.setFileDirectoryId(this.FileDirectoryId);

                localStorage.removeItem("updateFileId");

                this.dialogFormVisible = false;

                this.$emit("transfer", false);

                this.$parent.getSubCatalog(this.modelId);
            } else {
                loading.close();
            }
        },



        //移动
        move() {
            this.moveStoreFolderFile().then(() => {
                // this.$emit("cloudStoreList");
                this.$parent.$parent.cloudStoreList(this.parentId)
            })


        },
        async moveStoreFolderFile() {
            let folderId = this.$refs.tree.getCheckedNodes()[0].id
            let data = {

                folderId: folderId,
                alterCOS: this.$store.state.moveList
            }
            const res = await this.$https.moveStoreFolderFile(data)

            if (res.errCode == null) {
                this.$message({
                    message: "移动成功"
                    // type: "warning"
                });

                this.changeCloudTree(false)



            } else {
                // loading.close();
                var token = localStorage.getItem("token");
                if (token) {
                    this.$message({
                        message: res.errMessage
                        // type: "warning"
                    });
                }
            }
        },








        //查看同步情况
        async copyFolder() {

            let folderId = this.$refs.tree.getCheckedNodes()[0].id

            let data = {
                folderShowCO: this.$store.state.downloadData,
                folderId: folderId
            }
            const res = await this.$https.copyFolder(data)

            if (res.errCode == null) {
                this.$store.commit("changeDownloadShow", false)
                this.allFile(res.data)
            } else if (res.errCode == 412) {
                this.$store.commit("changeDownloadShow", false)
                //重名
                this.$confirm(res.errMessage, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    // type: "warning"
                })
                    .then(() => {
                        this.copyReplace(data)
                    })
                    .catch(() => {
                        this.$message({
                            // type: "info",
                            message: "已取消"
                        });
                    });




            }


        },
        async copyReplace(data) {


            const res = await this.$https.copyReplace(data)
            if (res.errCode == null) {
                this.updateFileOSSUrl(res.data)
            } else {

            }



        },


        //获取需要同步的文件列表
        async allFile(id) {
            let data = {
                id: id
            }
            const res = await this.$https.allFile(data)
            if (res.errCode == null) {
                this.updateFileOSSUrl(res.data)
            } else {

            }
        },


        //调取修改oss路径
        async updateFileOSSUrl(dataList) {


            let fileList = []



            for (let index = 0; index < dataList.length; index++) {


                let node = dataList[index]
                var time = new Date().getTime();
                let data = {

                }

                let imageUrl = [];
                imageUrl.push(node.imageUrl.split("?")[0]);
                data.id = node.id

                data.deputyUrl = INetwork.copyPaseFilesToSql(
                    node.deputyUrl.split(","),
                    node.id,
                    () => { }
                ).toString();
                data.fileUrl = INetwork.copyPaseFilesToSql(
                    node.fileUrl.split(","),
                    node.id,

                    () => { }
                )[0];
                data.imageUrl = INetwork.copyPaseFilesToSql(
                    imageUrl,
                    node.id,
                    () => { }
                )[0] + "?" + "time=" + time;

                fileList.push(data)



            }

            let data = {
                fileList: fileList
            }
            const res = await this.$https.updateFileOSSUrl(data)

            if (res.success == true) {

                this.$message({
                    // type: "info",
                    message: "同步成功"
                });

            } else {

                this.$message({
                    message: res.errMessage
                    // type: "warning"
                });


            }
            this.$store.commit("changeDownloadShow", false)





        },
        //限制单选
        groupHandleClick(data, checked, node) {
            if (checked) {
                this.$refs.tree.setCheckedNodes([data]);

                let id = this.$refs.tree.getCheckedNodes()[0] ? this.$refs.tree.getCheckedNodes()[0].id : ""
                this.folderPath(id)
            }
        },



        // 选择
        handleCheckChange(data, checked, indeterminate) {
            //判断是否有被选择

            let isChecked = this.$refs.tree.getCheckedNodes().length != 0
            this.isChecked = isChecked




        },

        //获取文件列表
        async getFolderList(_type) {
            const res = await this.$https.moveFolderTree({
                // projectId: localStorage.getItem("projectId"),

                // groupId: "c943db89-a6c1-4079-8fd4-06346dbab6b5"
                groupId:this.groupId,

            });

            // const loading = this.$loading({
            //   lock: true,
            //   text: "Loading",
            //   spinner: "el-icon-loading",
            //   background: "rgba(0, 0, 0, 0.7)"
            // });

            if (res.errCode == null) {
                let list = []
                list.push(res.data)

                this.dataList = list;
                this.loading = false
            } else {
                // loading.close();
                var token = localStorage.getItem("token");
                if (token) {
                    this.$message({
                        message: res.errMessage
                        // type: "warning"
                    });
                }
            }
        },


    }


}
</script>

<style lang="less" scoped>
.contentRight .directoryPath {
    height: 50px;

    width: 98%;
    margin: 0 auto;
    margin-top: 20px;
    background-color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-bottom: 20px;


}

.pathItem {
    display: inline-block;
}
</style>
<style scoped >
.contentRight .directoryPath .path {
    width: 98%;
    height: 100%;
    /* background-color: #06a7ff; */
    margin: 0 auto;
    display: flex;
    line-height: 50px;
    color: #06a7ff;


}

.tree {
    width: 100%;
    height: 300px;
    /* background-color: black; */
    margin: 10px auto;
    overflow-y: auto;
    /* overflow: hidden; */
}

/* 自定义tree组件 */

.tree /deep/ .el-tree-node__content {
    height: 23px;
    /* margin-top: 10px; */

}

.tree /deep/ .el-tree-node {
    margin-bottom: 140px;
}


.tree /deep/ .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
    background: rgba(75, 79, 82, 0);
    border: 2px solid rgba(5, 96, 253, 0.7);
    border-radius: 8px;
}

.tree /deep/.el-icon-caret-right:before {
    content: "";
    font-size: 25px;
    width: 10px;
    height: 10px;
    display: block;
    background: url("../../../assets/Resource/arrows.png") no-repeat center;
    background-size: 10px 10px;
    margin-right: 10px;
    margin-left: 10px;
}

.tree /deep/.el-checkbox__inner {
    border: none;
    box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.2);
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-radius: 3px;
    background-color: RGBA(5, 96, 253, 1);
}

/deep/ .el-checkbox__inner::after {
    box-sizing: content-box;
    content: "";
    position: absolute;
    left: 5px;
    top: 2px;
}

.el-tree /deep/.el-tree-node__expand-icon.is-leaf::before {
    background: #ffffff;
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    font-size: 16px;
    background-size: 16px;
    margin-right: 20px;
}

.tree /deep/ .el-tree .el-tree-node__expand-icon.expanded .el-icon-caret-right:before {
    content: "";
    font-size: 25px;
    width: 10px;
    height: 10px;
    display: block;
    background: url("../../../assets/Resource/arrows.png") no-repeat center;
    background-size: 10px 10px;
}

/* 滚动条 */
.tree::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: rgba(240, 240, 240, 1);
}

.tree::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
    border-radius: 10px;
    background-color: #e9f1fe;
}

.tree::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px #69a0fe;
    background: #69a0fe;
}

/* 设置鼠标悬停显示 */
.custom-tree-node {
    width: 100%;
}

.custom-tree-node-checked {
    border: 2px solid #333;
    border-radius: 10px;
}

.custom-tree-node-content {
    float: left;
}

.operation {
    visibility: hidden;
    margin-right: 30%;
    float: right;
}

.custom-tree-node:hover>.operation {
    visibility: visible;
}

input {
    background: #f7f7f7;
    border-radius: 10px;
    border: none;
    outline: none;
    padding-left: 10px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #929292;
    line-height: 40px;
}
</style>