<template>
    <div class="redact">
        <div class="top">
            <div class="topLeft" @click="directoryStructure()">
                <img src="../../../assets/PlugIn/cloudStore/icon.png" alt="">

                <span class="text">
                    插件文件管理

                </span>
            </div>
            <div class="topTitle">3D云店设置</div>
            <div class="topRight">
                <div class="topRightItem"><img src="../../../assets/PlugIn/cloudStore/save.png" alt="">
                    <span @click="mySave()">
                        保存
                    </span>
                </div>
                <div class="topRightItem"><img src="../../../assets/PlugIn/cloudStore/preview.png" alt="">

                    <span>
                        预览
                    </span>
                </div>
                <div class="topRightItem" @click="GenerateLinks()"><img src="../../../assets/PlugIn/cloudStore/create.png"
                        alt=""><span>
                        生成
                    </span>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="contentLeft">
                <div class="menuList">
                    <div :class="{ 'pitchOnMenuListItem': item.id == currentFunctionsId, 'menuListItem': true }"
                        v-for="item in menuList" :key="item.id" @click="switchFunction(item.id)">
                        <div class="icon">
                            <img v-if="(item.id == currentFunctionsId)" :src="item.selectImg" alt="">
                            <img v-else :src="item.icon" alt="">
                        </div>
                        <span class="menuListItemTitle">
                            {{ item.name }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="contentRight">
                <!-- v-show="currentFunctionsId == 2"  -->
                <BasicSetting ref="BasicSetting" v-show="currentFunctionsId == 1"></BasicSetting>
                <VisualAngle class="VisualAngle" v-if="currentFunctionsId == 2" :slideshowList="slideshowList">
                </VisualAngle>
                <StoreDetails v-show="currentFunctionsId == 3" :modelDataList="modelDataList"></StoreDetails>
            </div>
        </div>
        <!-- 分享云店 -->
        <el-dialog :visible="shareHistory" width="25%" :modal="true" title="生成" center append-to-body
            :before-close="shareHistoryClose" top="23vh">
            <el-form :model="ruleForm" status-icon :rules="rules" label-position="left" ref="ruleForm" label-width="5.85938vw"
                class="demo-ruleForm">
                <el-form-item label="作品权限" prop="">
                    <el-select v-model="ruleForm.isOpen" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"
                            :disabled="item.disabled">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="ruleForm.isOpen == 2" label="设置访问密码" prop="password">
                    <el-input v-model="ruleForm.password" placeholder="请输入6位数字组合" maxlength="6">
                    </el-input>
                </el-form-item>
                <el-form-item label="分享链接有效期">
                    <el-radio-group v-model="ruleForm.resource">
                        <el-radio :label="1">7天</el-radio>
                        <el-radio :label="2">15天</el-radio>
                        <el-radio :label="3">30天</el-radio>
                        <el-radio :label="4">永久</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <el-button @click="shareHistoryClose">取消</el-button>
                    <el-button type="primary" @click="submitForm('ruleForm')">生成</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :visible="$store.state.cloudTree" width="25%" :modal="true" title="请选择要保存到的目录" append-to-body
            :before-close="downloadClose" top="23vh">
            <SelectFolderTree v-if="$store.state.cloudTree" :groupId="teamId"></SelectFolderTree>
        </el-dialog>
    </div>
</template>

<script>

// 基础设置

import BasicSetting from "./BasicSetting"
import StoreDetails from "./StoreDetails"
import VisualAngle from "./VisualAngle"
// import MediaSet from "./MediaSet"
// import MapSet from "./MapSet"
// import FurnitureDesign from "./FurnitureDesign"
// import CommodityDesign from "./CommodityDesign"

// import StoreSetup from "./StoreSetup"
// import Externalities from "./Externalities"

// import EffectPicture from "./EffectPicture"

import SelectFolderTree from "./SelectFolderTree"


import basics from "../../../assets/PlugIn/cloudStore/basics.png"

import angleOfview from "../../../assets/PlugIn/cloudStore/angleOfview.png"
import media from "../../../assets/PlugIn/cloudStore/media.png"
import map from "../../../assets/PlugIn/cloudStore/map.png"

import icon from "../../../assets/PlugIn/cloudStore/basics.png"
import commodity from "../../../assets/PlugIn/cloudStore/commodity.png"
import furniture from "../../../assets/PlugIn/cloudStore/furniture.png"

import store from "../../../assets/PlugIn/cloudStore/store.png"
import external from "../../../assets/PlugIn/cloudStore/external.png"
import result from "../../../assets/PlugIn/cloudStore/result.png"
import viewSelection from "../../../assets/PlugIn/cloudStore/result.png"
import selectVisualAngle from "../../../assets/PlugIn/cloudStore/selectVisualAngle.png"
import catalogue from "../../../assets/PlugIn/cloudStore/catalogue.png"



import SelectBase from "../../../assets/PlugIn/cloudStore/SelectBase.png"

import { ICloudSores } from "../CloudStores/cloudSoresThree/ICloudSores"

import { CubeData } from "../CloudStores/cloudSoresThree/factory/CubeData"
import * as THREE from 'three';

import { IScene } from "../../../threejs/interface/IScene";

import { GameObject } from "../../../threejs/object/GameObject"


import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

// angleOf view.png

export default {
    data() {
        return {

            options: [
                {
                    value: '1',
                    label: '公开作品'
                },
                {
                    value: '2',
                    label: '私密作品'
                },

            ],
            ruleForm: {
                password: "",
                resource: 1,
                isOpen: "1",
            },
            shareUrl: "",
            rules: {
                password: [
                    { required: true, message: '请输入密码', trigger: 'change' }
                ]
            },
            shareHistory: false,
            share: false,
            menuList: [
                {
                    id: "1",
                    icon: basics,
                    selectImg: SelectBase,
                    name: "基础设置"
                },
                {
                    id: "2",
                    icon: angleOfview,
                    selectImg: selectVisualAngle,

                    name: "实景编辑"
                },
                {
                    id: "3",
                    icon: furniture,
                    selectImg: catalogue,



                    name: "店铺详情设置"
                },

            ],
            currentFunctionsId: 2,
            checked: false,
            checked2: false,
            checked3: false,
            checked4: false,
            id: "",
            fileUrl: "",
            deputyUrl: "",

            fileSize: 0,
            folderId: "",


            cameraList: [
                {
                    id: "1",
                    name: "渲染相机1",
                    url: ""
                },
                {
                    id: "2",
                    name: "渲染相机1",
                    url: ""
                },
                {
                    id: "3",
                    name: "渲染相机1",
                    url: ""
                },
                {
                    id: "4",
                    name: "渲染相机1",
                    url: ""
                }

            ],
            optForCameras: {},
            projectId: "",
            judgeStoreSaveData: false,
            temporaryId: "",
            // 3d实例
            ICloudSoresThree: {},
            // 相机列表
            slideshowList: [],
            modelDataList: [],
            loderOk: false,
            // 模型方块数组
            CubeArr: [],
            // 团队id
            teamId: "",
            beforeUnload_time: 0,

        }
    },

    mounted() {

        if (this.$route.query.parentId) {
            this.getCloudStore(this.id);

            this.storeLocal(this.id, 1)

            this.LocalTime = setTimeout(() => {
                this.storeLocal(this.id, 1)


            }, 1000 * 60 * 15);

        }




    },
    created() {
        window.addEventListener("beforeunload", this.beforeUnloadHandler, false)
        window.addEventListener('unload', e => this.unloadHandler(e))
        let teamId = this.$route.query.groupId


        if (teamId) {
            this.teamId = teamId
        }

        console.log(teamId, "teamId");

        // JSON.parse(obj)
        if (this.$route.query.parentId) {
            //回显
            this.id = this.$route.query.parentId;
            this.temporaryId = this.algorithm()
            this.ICloudSoresThree = new ICloudSores()

            this.ICloudSoresThree.changeSqlId(this.id)

            let _this = this
            // this.ICloudSoresThree.downloadData(this.id, this.id, function (ossData) {
            //     console.log(ossData, "ossData");
            //     _this.ICloudSoresThree.init(ossData.id, ossData.cameraList, ossData.modelList)
            //     _this.ICloudSoresThree.setCubeList(ossData.CubeList)



            //     _this.getData()
            //     _this.loderOk = true
            // })



        }
        // console.log(!this.id, "!this.id");
        if (!this.id) {

            let data = JSON.parse(this.$route.query.cloudsScene)


            let projectId = this.$route.query.projectId


            if (projectId) {
                this.projectId = projectId
            }

            let teamId = this.$route.query.teamId
            if (teamId) {
                this.teamId = teamId
            }
            console.log(data, "cloudsScene")
            this.fileSize = data.fileSize





            let cubeDataList = []

            // for (let data of CubeArr) {
            //     let Cube = new CubeData()

            //     const material = new THREE.MeshBasicMaterial({
            //         color: 0x00ff00,
            //         fog: false,
            //         toneMapped: false,
            //     });
            //     Cube.dataByCreateCube(data, material)
            //     cubeDataList.push(Cube)
            // }
            // this.CubeArr= cubeDataList


            console.log(this.CubeArr, "this.CubeArr");

            // if (data.deputyUrl) {


            //     this.deputyUrl = INetwork.copyPaseFilesToSql(
            //         data.deputyUrl.split(","),
            //         data.id,
            //         () => { }
            //     ).toString();
            // } else {
            //     this.deputyUrl == ""
            // }
            // if (data.fileUrl) {


            //     this.fileUrl = INetwork.copyPaseFilesToSql(
            //         data.fileUrl.split(","),
            //         data.id,

            //         () => { }
            //     )[0];
            // } else {
            //     this.fileUrl = ""
            // }
            this.temporaryId = this.algorithm()
            // this.threeInit(this.temporaryId)
            this.loderOk = true



        }
    },
    destroyed() {
        // this.ICloudSoresThree.destroyedCloydSores()

        this.storeLocal(this.id, 0)


        clearInterval(this.LocalTime);
        this.LocalTime = null;

        window.removeEventListener("beforeunload", this.beforeUnloadHandler, false)
        window.removeEventListener('unload', e => this.unloadHandler(e))
    },
    methods: {
        ...mapMutations(["changeCloudFileData", "changeCloudTree", "changeCloudTreeOperationType"]),

        // 1==上锁,0==解锁
        async storeLocal(id, type) {

            let data = {
                id: id,
                local: type
            }
            const res = await this.$https.storeLocal(data)


        },


        threeInit(id) {
            let cameraList = JSON.parse(this.$route.query.cameraList)
            let modelLis = JSON.parse(this.$route.query.modelLis)

            console.log(cameraList, modelLis, "modelLis");


            // let cameraList3D= IScene.getTypeObjects(GameObject.GameObjectType.e_CAMERA_EQUIRECT)

            // let modelLis3D= IScene.getTypeObjects(GameObject.GameObjectType.e_GLTF)


            this.ICloudSoresThree = new ICloudSores()
            this.ICloudSoresThree.init(id, cameraList, modelLis)
            let list = this.ICloudSoresThree.getCameraDataList()

            let CubeArr = JSON.parse(this.$route.query.CubeArr)
            this.ICloudSoresThree.setCubeList(CubeArr)


            this.storeModelInformation().then(() => {
                this.getData()

            })




        },

        async storeModelInformation() {
            let modelIds = this.ICloudSoresThree.convertDataToSql()



            let data = {
                storeModelCOS: modelIds,
                projectId: this.projectId,
            }
            const res = this.$https.storeModelInformation(data)

            if (res.success == true) {
                this.ICloudSoresThree.convertSqlToData(res.data)
            }
        },



        getData() {
            this.slideshowList = this.ICloudSoresThree.getCameraDataList()
            this.modelDataList = this.ICloudSoresThree.getModelDataList()
            console.log(this.modelDataList, "this.modelDataList");
        },
        // 生成id
        algorithm() {
            let abc = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'g', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
            let [max, min] = [Math.floor(Math.random() * (10 - 7 + 1) + 1), Math.floor(Math.random() * (17 - 10 + 1) + 17)];
            abc = abc.sort(() => 0.4 - Math.random()).slice(max, min).slice(0, 8).join("");
            var a = new Date().getTime() + abc;
            return a
        },

        beforeUnloadHandler(e) {
            e.returnValue = "离开此页面？" // 此处返回任意字符串，不返回null即可，不能修改默认提示内容


        },
        unloadHandler() {

            this.storeLocal(this.id, 0)


            clearInterval(this.LocalTime);
            this.LocalTime = null;

        },

        // 切换选中相机
        switchingCameras(item) {
            this.optForCameras = item
        },
        //关闭树弹窗
        downloadClose() {
            this.changeCloudTree(false)
        },

        //保存
        async mySave() {
            // if (this.currentFunctionsId == 1) {
            await this.judgeStoreSave(this.id)
            await this.addCloudStore().then((test) => {
                return test
            })
            // } else if (this.currentFunctionsId == 2) {
            //     this.$message({
            //         // type: "info",
            //         message: "该页面保存暂不可用，当前仅基础设置保存可用"
            //     });
            // } else if (this.currentFunctionsId == 3) {
            //     this.$message({
            //         // type: "info",
            //         message: "该页面保存暂不可用，当前仅基础设置保存可用"
            //     });
            // }
        },

        // 云店数据回显
        async getCloudStore(id) {
            let data = {
                id: id
            }
            const res = await this.$https.getCloudStore(data)
            if (res.success == true) {
                let effectPicture = res.data.effectPicture
                let myEffectPicture = []

                effectPicture.forEach((element, index) => {
                    let data = {
                        url: element,
                        id: index
                    }
                    myEffectPicture.push(data)
                });
                let openingHours = []
                openingHours[0] = res.data.start
                openingHours[1] = res.data.end
                // let arr=[]
                // let effectList=res.data.effectPicture
                //     effectList.forEach(element => {

                //         let data={
                //             url:element
                //         }
                //         arr.push(data)

                //     });
                this.projectId = res.data.projectId
                this.teamId = res.data.groupId

                this.$refs.BasicSetting.vestIn = res.data.projectName
                this.$refs.BasicSetting.shopType = res.data.storeProperty
                this.$refs.BasicSetting.openingHours = openingHours
                this.$refs.BasicSetting.address = res.data.storeSite
                this.$refs.BasicSetting.shopName = res.data.name
                this.$refs.BasicSetting.introduce = res.data.introduce
                this.$refs.BasicSetting.ichnographyUrl = res.data.plan
                this.$refs.BasicSetting.ichnographySize = res.data.planSize
                this.$refs.BasicSetting.backgroundMusicUrl = res.data.music
                this.$refs.BasicSetting.shoppingMusicUrl = res.data.shoppingGuideVoice
                this.$refs.BasicSetting.effectList = res.data.effectPicture
                this.deputyUrl = res.data.deputyUrl
                this.fileUrl = res.data.fileUrl
                this.fileSize = res.data.fileSize
                this.$refs.BasicSetting.backgroundMusic = res.data.showMusic
                this.$refs.BasicSetting.backgroundMusicShow = res.data.showMusic
                this.$refs.BasicSetting.shoppingMusic = res.data.showShoppingGuideVoice
                this.$refs.BasicSetting.shoppingMusicShow = res.data.showShoppingGuideVoice
                this.$refs.BasicSetting.ichnography = res.data.showPlan
                this.$refs.BasicSetting.ichnographyShow = res.data.showPlan
                this.$refs.BasicSetting.effect = res.data.showEffectPicture
                this.$refs.BasicSetting.effectShow = res.data.showEffectPicture
                this.$refs.BasicSetting.listShow = res.data.showEffectPicture
            } else {
                this.$message({
                    // type: "info",
                    message: "获取数据异常"
                });

            }
        },
        // 切换功能
        switchFunction(id) {

            if (this.$route.query.parentId) {
                this.currentFunctionsId = id
            } else {
                this.currentFunctionsId = id
                // this.$confirm("检测到您还没有保存请先保存?", "提示", {
                //     confirmButtonText: "确定",
                //     cancelButtonText: "取消",
                //     type: "warning"
                // })
                //     .then(() => {

                //         //     if ( this.currentFunctionsId==1) {
                //         //     this.addCloudStore().then(
                //         //     (stert)=>{
                //         //         if(stert){
                //         //             this.currentFunctionsId = id
                //         //             this.getCloudStore(this.id);

                //         //         }

                //         //     }
                //         //     )
                //         // }else{
                //         //     this.$message({
                //         //     // type: "info",
                //         //     message: "保存成功"

                //         // });

                //         // }

                //     })
                //     .catch(() => {

                //         //      this.currentFunctionsId = id

                //         //      this.getCloudStore(this.id);

                //         //      this.$message({
                //         //     // type: "info",
                //         //     message: "保存失败"

                //         // });
                //     });

            }
        },
        //跳转到项目页
        directoryStructure() {
            let _this = this
            this.$confirm("系统可能不会保存您所做的更改。", "返回到项目页？", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",

            })
                .then(() => {
                    let teamId = _this.teamId
                    // this.mySave().then((test)=>{
                    //     if (test) {
                    this.$router.push({
                        path: '/PlugIn/CloudStores/FileDirectory', query: {
                            groupId: teamId
                        }
                    })
                    // }
                    // })

                })
                .catch(() => {
                    // this.$router.push({ path: '/PlugIn/CloudStores/FileDirectory', })
                });
        },
        shareHistoryClose() {
            this.shareHistory = false

        },
        GenerateLinks() {
            console.log(this.id, "this.id");
            if (this.id) {
                this.shareHistory = true
            } else {
                this.$message({
                    // type: "info",
                    message: "请先保存"
                });
            }

        },
        getNum(num) {
            var random = Math.floor(
                (Math.random() + Math.floor(Math.random() * 9 + 1)) *
                Math.pow(10, num - 1)
            );
            return random;
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let thisUrl = window.location.href.split('#')[0] + '#'
                    // let url = thisUrl + "/PlugIn/CloudStores/FileDirectory"
                    let url = 'https://www.yicreator.com/plugIn/index.html#/'
                    // https://www.yicreator.com/plugIn/index.html#/

                    let id = this.id
                    let time = new Date().getTime()
                    let stochastic = this.getNum(10)


                    // if (this.share == false) {

                    this.shareUrl = url + '?id=' + id + '&&number=' + stochastic + '&&time=' + time

                    // }

                    // this.share = true

                    let limit = this.ruleForm.resource == "1" ? 7 : this.ruleForm.resource == "2" ? 14 : 0


                    if (this.ruleForm.resource == "1") {
                        limit = 7
                    } else if (this.ruleForm.resource == "2") {
                        limit = 15

                    } else if (this.ruleForm.resource == "3") {
                        limit = 30

                    } else if (this.ruleForm.resource == "4") {
                        limit = 0

                    }



                    this.saveShareLink(this.id, limit, this.ruleForm.password, this.ruleForm.isOpen, stochastic, time)


                } else {

                    return false;
                }
            });


        },
        //分享接口
        async saveShareLink(id, days, password, isOpen, number, time) {
            let data = {
                id: id,
                days: days,
                password: password,
                isOpen: isOpen,
                number: number,
                time: time
            }
            const res = await this.$https.sceneryCloudStoreShare(data)

            if (res.success == true) {

                // "这是已复制的文本，使用ctrl+v即可粘贴

                let myText
                if (isOpen == 1) {

                    if (days == 0) {
                        myText = `云店的访问地址是：${this.shareUrl} 链接有效期永久；`

                    } else {

                        myText = `云店的访问地址是：${this.shareUrl} 链接有效期 ${days}天；`
                    }

                } else {

                    if (days == 0) {
                        myText = `云店的访问地址是：${this.shareUrl} 链接有效期 永久；访问码：${password}`


                    } else {
                        myText = `云店的访问地址是：${this.shareUrl} 链接有效期 ${days}天；访问码：${password}`


                    }

                }

                let { text } = this.$copyText(myText);
                this.$message("复制成功")
                this.shareHistory = false
            } else {
                if (token) {
                    this.$message({
                        message: res.errMessage
                        // type: "warning"
                    });
                }
            }

        },
        // 判断是否是编辑
        async judgeStoreSave(id) {

            let data = {
                storeId: id,
            }

            const res = await this.$https.judgeStoreSave(data)

            if (res.success == true) {
                this.judgeStoreSaveData = res.data
            } else {

            }



        },




        //创建编辑
        async addCloudStore() {

            let isTrue = this.$refs.BasicSetting.shopName.split(" ").join("").length == 0

            let isShopType = this.$refs.BasicSetting.shopType
            let isOpeningHours = this.$refs.BasicSetting.openingHours
            let isIntroduce = this.$refs.BasicSetting.introduce.split(" ").join("").length == 0
            let isAddress = this.$refs.BasicSetting.address.split(" ").join("").length == 0

            let isbackgroundMusic = this.$refs.BasicSetting.backgroundMusic && JSON.stringify(this.$refs.BasicSetting.backgroundMusicUrl) == '{}'


            let iseffect = this.$refs.BasicSetting.effect && this.$refs.BasicSetting.effectList.length == 0

            let isichnography = this.$refs.BasicSetting.ichnography && this.$refs.BasicSetting.ichnographyUrl == ""
            let isshoppingMusic = this.$refs.BasicSetting.shoppingMusic && JSON.stringify(this.$refs.BasicSetting.shoppingMusicUrl) == '{}'

            let size = 0
            let ichnographySize = this.$refs.BasicSetting.ichnographySize != null ? this.$refs.BasicSetting.ichnographySize : 0

            // console.log(this.$refs.BasicSetting.backgroundMusicUrl);
            let backgroundMusicUrlSize = this.$refs.BasicSetting.backgroundMusicUrl != null ? this.$refs.BasicSetting.backgroundMusicUrl.size : 0

            let shoppingMusicUrlSize = this.$refs.BasicSetting.shoppingMusicUrl != null ? this.$refs.BasicSetting.shoppingMusicUrl.size : 0


            if (this.$refs.BasicSetting.effectList) {


                this.$refs.BasicSetting.effectList.forEach(element => {
                    size = size + element.size
                });
            }

            this.fileSize = size + ichnographySize + backgroundMusicUrlSize + shoppingMusicUrlSize




            if (isTrue) {

                this.$message({
                    // type: "info",
                    message: "请填写项目名称"
                });
                return false

            } else if (isShopType === "") {

                this.$message({
                    // type: "info",
                    message: "请选择类型"
                });
                return false

            } else if (isIntroduce) {
                this.$message({
                    // type: "info",
                    message: "请填写店铺介绍"
                });
                return false
            } else if (isOpeningHours == null) {
                this.$message({
                    // type: "info",
                    message: "请填写店铺营业时间"
                });
                return false
            } else if (isbackgroundMusic) {
                this.$message({
                    // type: "info",
                    message: "请上传背景音乐"
                });
                return false

            } else if (iseffect) {
                this.$message({
                    // type: "info",
                    message: "请上传效果图"
                });
                return false
            } else if (isichnography) {
                this.$message({
                    // type: "info",
                    message: "请上传平面图"
                });
                return false
            } else if (isshoppingMusic) {
                this.$message({
                    // type: "info",
                    message: "请上传导购语音"
                });
                return false
            }
            else if (isAddress) {
                this.$message({
                    // type: "info",
                    message: "请填写地址"
                });
                return false
            }

            else {

                // let myEffectPicture = this.$refs.EffectPicture.effectPicture
                // let effectPicture = []
                // myEffectPicture.forEach(element => {
                //     effectPicture.push(element.url)
                // });



                let data = {
                    // id
                    id: this.id,
                    // 文件夹id
                    folderId: this.folderId,

                    projectId: this.projectId,
                    name: this.$refs.BasicSetting.shopName,
                    storeProperty: this.$refs.BasicSetting.shopType,
                    introduce: this.$refs.BasicSetting.introduce,
                    start: this.$refs.BasicSetting.openingHours[0],
                    end: this.$refs.BasicSetting.openingHours[1],
                    storeSite: this.$refs.BasicSetting.address,
                    plan: this.$refs.BasicSetting.ichnographyUrl,
                    planSize: this.$refs.BasicSetting.ichnographySize ? this.$refs.BasicSetting.ichnographySize : 0,

                    music: this.$refs.BasicSetting.backgroundMusicUrl,
                    shoppingGuideVoice: this.$refs.BasicSetting.shoppingMusicUrl,
                    effectPicture: this.$refs.BasicSetting.effectList,
                    fileUrl: this.deputyUrl,
                    deputyUrl: this.deputyUrl,
                    fileSize: this.fileSize ? this.fileSize : 0,
                    // showPlan:this.$refs.BasicSetting.shoppingMusicUrl,

                    showPlan: this.$refs.BasicSetting.ichnography,

                    showMusic: this.$refs.BasicSetting.backgroundMusic,
                    showShoppingGuideVoice: this.$refs.BasicSetting.shoppingMusic,
                    showEffectPicture: this.$refs.BasicSetting.effect,
                    groupId: this.teamId
                }
                console.log("要穿的数据", data)

                if (this.id && this.judgeStoreSaveData) {
                    const res = await this.$https.addSceneryCloudStore(data)

                    if (res.success == true) {
                        // this.$router.push({ path: '/PlugIn/CloudStores/FileDirectory', })

                        this.ICloudSoresThree.changeSqlId(this.id)
                        this.addStoreModel()
                        this.ICloudSoresThree.save(() => {

                            this.$message({
                                // type: "info",
                                message: "保存成功"

                            });
                        })



                        return true


                    } else {
                        this.$message({
                            // type: "info",
                            message: res.errMessage
                        });
                    }

                } else {
                    this.changeCloudTree(true)
                    this.$store.commit("changeCloudData", data)
                    this.changeCloudTreeOperationType(3)

                    window.removeEventListener("beforeunload", this.beforeUnloadHandler, false)
                    window.removeEventListener('unload', e => this.unloadHandler(e))

                    return true



                }




            }
        },
        // 模型默认数据修改
        async addStoreModel() {
            let modelList = this.ICloudSoresThree.convertDataToSql()
            let data = {
                projectId: this.projectId,
                storeModelCOS: modelList,
                groupId: this.teamId,
            }
            const res = await this.$https.addStoreModel(data);
            if (res.success == true) {

            }
        }

    },
    components: {
        // 视角
        VisualAngle,
        // 文件树
        SelectFolderTree,


        // 基础设置
        BasicSetting,
        StoreDetails
    },


}
</script>

<style lang="less" scoped>
.redact {
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .top {
        width: 100%;
        height: 60px;
        // background: rgb(206, 206, 206);
        background: rgba(5, 96, 253, 1);

        display: flex;
        justify-content: space-between;

        .topLeft {
            width: 110px;
            margin-left: 10px;
            line-height: 70px;
            color: #fff;
            font-size: 14px;
            display: flex;

            flex-direction: row;

            align-items: center;
            justify-content: space-between;

            img {
                line-height: 70px;
                width: 20px;
            }


        }

        .topTitle {

            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 50px;
        }

        .topRight {
            width: 18%;
            display: flex;
            justify-content: space-around;
            line-height: 70px;

            img {
                width: 18px;

            }

            .topRightItem {
                width: 60px;

                display: flex;

                flex-direction: row;
                color: #fff;

                align-items: center;
                cursor: pointer;
                justify-content: space-between;

                span {

                    height: 69px;
                }

            }
        }

    }

    .content {
        width: 100%;
        height: 94%;
        // background-color: #fff;
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        .contentLeft {
            width: 250px;
            height: 100%;
            // background-color:rgb(206, 206, 206);
            background-color: #fff;

            .menuList {
                margin-top: 20px;

                .pitchOnMenuListItem {
                    height: 60px;
                    margin-top: 20px;
                    background-color: rgba(230, 239, 255, 1);
                    // box-shadow: 1px 1px 6px 1px rgba(33, 35, 49, 0.1);
                    text-align: center;
                    cursor: pointer;
                    border-left: 5px solid #0560FD;

                    color: #0560FD;


                }

                .menuListItem {
                    height: 60px;
                    margin-top: 20px;

                    // box-shadow: 1px 1px 6px 1px rgba(33, 35, 49, 0.1);
                    text-align: center;
                    cursor: pointer;



                    .icon {
                        float: left;
                        height: 50px;
                        text-align: center;
                        line-height: 70px;
                        margin-left: 20px;

                        img {
                            width: 20px;
                        }
                    }

                    .menuListItemTitle {
                        float: left;
                        line-height: 60px;
                        margin-left: 20px;

                    }
                }
            }
        }

        .contentRight {

            height: 100%;
            width: 100%;


        }


    }

}
</style>