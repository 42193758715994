class ModelData {
    constructor() {
        this.m_id = null;
        this.m_name = "";
        this.m_img = "";
        this.m_number=0;
        this.m_furnitureData = {
            explicitName: "",
            len: "",
            breadth: "",
            altitude: "",
            describe: "",
            showImg: ""
        }
        this.m_commodityData = {
            showName: "",
            guidingPrice: "",
            describe: "",
            showImg: "",
            goToUrl: "",
        }
        // 0 ： 无 1：商品 2：家具
        this.m_type = 0
    }
    setFurnitureData(data) {
        let obj = {
            explicitName: data.explicitName,
            len: data.len,
            breadth: data.breadth,
            altitude: data.altitude,
            describe: data.describe,
            showImg: data.showImg,
        }
        this.m_furnitureData = obj
        this.m_type = 2
        this.m_commodityData = {
            showName: "",
            guidingPrice: "",
            describe: "",
            showImg: "",
            goToUrl: "",
        }
    }
    setCommodityData(data) {
        let obj = {
            showName: data.showName,
            guidingPrice: data.guidingPrice,
            describe: data.describe,
            showImg: data.showImg,
            goToUrl: data.goToUrl,
        }
        this.m_commodityData = obj
        this.m_type = 1
        this.m_furnitureData = {
            explicitName: "",
            len: "",
            breadth: "",
            altitude: "",
            describe: "",
            showImg: ""
        }
    }
    getFurnitureDat() {
        return this.m_furnitureData
    }
    getCommodityData() {
        return this.m_commodityData
    }
    clearMark() {
        this.m_type = 0
        this.m_furnitureData = {
            explicitName: "",
            len: "",
            breadth: "",
            altitude: "",
            describe: "",
            showImg: ""
        }
        this.m_commodityData = {
            showName: "",
            guidingPrice: "",
            describe: "",
            showImg: "",
            goToUrl: "",
        }
    }
}
export { ModelData };
