<template>
  <div class="box">
    <div class="content">
      <div class="title">

        <span class="titleName">
          上传地图
        </span>
        <el-button type="primary" @click="btnChange()" >选择上传</el-button>

        <input type="file" value="选择图片" ref="file" @change="fileChange($event)" accept="image/png, image/jpeg, image/jpg" style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0"/>
      </div>
      <div class="list">


        <div class="mapList">
          <div class="item">

            <img v-if="!filestate" :src="map" alt="">
            <i  class="el-icon-plus" v-if="filestate"  @click="btnChange()"></i>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { OssManager } from "../../../threejs/network/OssManager";


import {MainObjects} from "../../../threejs/common/MainObjects";
export default {
  data() {
    return {
      filestate: "",
      // img地址
      map: "",
      filestate: true,
    }
  },
  mounted(){
    this.reNum = this.getNum(20); //生成图片时 造随机数

  },
  methods: {
    getNum(num) {
      var random = Math.floor(
        (Math.random() + Math.floor(Math.random() * 9 + 1)) *
          Math.pow(10, num - 1)
      );
      return random;
    },
    fileChange(e){

      var _this = this;
        
      let https = MainObjects.Network.m_http;

    //   let getHtttps=MainObjects.Network.m_http;
      
      const files = e.target.files
      console.log(files,"files")

      let data = new OssManager(MainObjects.Network.m_bucketName);

      let url =   data.getCloudShopFloder("basicInformation", this.reNum)


      let urls = https + url + files[0].name;
      function uploadFinish(list) {
        let urls = https + url + files[0].name;
        _this.map = urls+"?time=" +this.reNum;

     
        _this.filestate = false;
      }
      data.f_finishCallback = uploadFinish;
      data.upload(files[0],url)

    },

    btnChange(){
      this.$refs.file.click()
    },
  }

}
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;

  .content {
    width: 80%;
    margin: 0 auto;

    .title {
      font-size: 18px;
      margin-top: 20px;
      color: #000;

      .titleName {
        margin-right: 10px;

      }

    }

    .list {
      height: 760px;
      overflow-y: auto;
      width: 100%;


      .mapList {
        width: 90%;
        margin: 0 auto;

        .item {
          margin-top: 30px;
          width: 200px;
          height: 150px;
          background-color: #fff;
          box-shadow: 0px 0px 6px 0px rgba(33,35,49,0.1);
            position: relative;
            i{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
            img{
                width: 100%;
                height: 100%;
            }
        }

      }
    }

  }
}
</style>