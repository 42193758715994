<template>
    <div class="container">
        <!-- 
        <div class="top">
            <div class="pmImg" @click="establishTeam">
         
          <span>返回项目</span>
        </div>
        </div> -->
        <div class="header">


            <div class="pmImg" @click="goToProject()">
                <!-- <img src="../../assets/group/newProject.png" alt /> -->
                <span class="">返回项目</span>

            </div>
        </div>


        <div class="main">

            <div class="tableTop" >
                <div class="pmImg" @click="verifyCopy">
                    <!-- <img src="../../assets/group/newProject.png" alt /> -->
                    <span class="" >确认</span>

                </div>
            </div>

            <el-table :data="tableData" style="width: 100%" :row-key="getRowKey" :max-height="screenHeight"
                @selection-change="handleSelectionChange">
                <el-table-column type="index" label="序号" width="180" align="center"></el-table-column>
                <el-table-column label="项目名称" style="width: 20%" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="所属团队" style="width: 20%" align="center">
                    <template>
                        <!-- <el-tag size="medium">{{ scope.row.path  }}</el-tag> -->
                        <span>云盘</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建人" style="width: 20%" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.versionNum }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="版本号" style="width: 20%" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.updateTime }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="最后修改时间" style="width: 20%" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.updateTime }}</span>
                    </template>
                </el-table-column>

                <el-table-column type="selection" :reserve-selection="true" width="55">
                </el-table-column>



            </el-table>
            <div class="paging">

                <div class="totalNumber">
                    共{{ totals }}条数据
                </div>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[10, 20, 15]" :page-size="pageSize"
                    layout="prev, pager, next" :total="totals" align="right">
                </el-pagination>
            </div>
        </div>

        <div class="popup" v-show="newFolder">
            <div class="new">新建项目</div>
            <p class="close" @click="closeChange1">
                <img src="../../assets/Project/close.png" alt />
            </p>
            <div class="name">
                <p>项目名称：</p>
                <div class="ipt_box">
                    <input class="input" type="text" @keydown.enter="setChange" v-model="projectName"
                        placeholder="请输入名称" @focus="Focus" @blur="Blur" />
                </div>
            </div>

            <div class="footer">
                <div class="privacy">
                    <input type="checkbox" v-model="privacyPolicy" class="input" />
                    <p>
                        勾选表示同意
                        <router-link to="/TermsOfProject">【新建项目条款】</router-link>
                    </p>
                </div>
                <div class="enter">
                    <div class="set verify" @click="setChange">创建</div>
                    <div class="set cancel" @click="setCancel">取消</div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
import { ISetting } from "../../threejs/interface/ISetting";

export default {
    name: "ProjectName",
    data() {
        return {
            //创建团队显示与否
            createdTeamShow: false,

            //团队id
            teamId: "",
            //成员在该团队的等级
            teamLevel: "",

            newFolder: false,

            tableData: [],
            //项目名字
            projectName: "",
            //新名字
            newName: "",
            //控制新名字显示隐藏
            setNewShow: false,
            newNameId: "",
            //同意新建项目条款
            privacyPolicy: false,
            //未上锁
            local: false,

            size: 15,
            page: 1,
            listLoading: true,
            pageSize: 15,
            totals: 10,
            currentPage: 1,
            //删除名字
            deleteName: "",
            //删除
            deleteProject: false,
            //删除id
            deletId: "",
            //用户输入的删除名字
            inputDeleteName: "",
            //table自适应高度
            screenHeight: `${document.documentElement.clientHeight}` - 300,
            //创建项目时用到的群组id
            createProjectId: "",
            // 被选中的数据
            selectOrderArr: [],
        };
    },
    components: {},
    mounted() {


        //团队id
        let teamId = localStorage.getItem("teamId");
        //成员在该团队的等级

        let teamLevel = localStorage.getItem("teamLevel");

        if (teamId && teamLevel) {

            this.$store.commit("changeUserRank", teamLevel)

            this.teamId = teamId;
            this.teamLevel = teamLevel;
        } else {
            localStorage.clear();
            router.replace({
                path: "/OfficialWebsite/HomePage/HomePageContent"
            });
        }

        this.projectList(this.page, this.size, this.teamId);
        const self = this;

        document.onkeypress = function (e) {
            var keycode = document.all ? event.keyCode : e.which;
            if (keycode == 13) {
                return false;
                if (self.newFolder) {
                    self.setChange();
                } else if (self.deleteProject) {
                    self.deleteOk();
                }
            }
        };

        window.onresize = function temp() {
            self.screenHeight = `${document.documentElement.clientHeight}` - 142;
        };

        // let projectId = localStorage.getItem("projectId");

        // if (projectId != null && projectId != undefined) {


        //     this.$router.replace({ path: "/Home" });
        // }



    },
    methods: {





        // 确认同步项目数据
        async verifyCopy() {
            let id = this.$route.query.id
            let selectOrderArr = this.selectOrderArr
            
            let projectId = []

            for (let index = 0; index < selectOrderArr.length; index++) {
             
                projectId.push(selectOrderArr[index].id)

            }

            let data = {
                id: id,
                projectId: projectId
            }
          
            const res = await this.$https.shareSharedLibrary(data)

            if (res.errCode == null) {
                this.$message({

                showClose: true,
                message: "操作成功",
                // type: "warning",
                });

            } else {
                

                this.$message({

                    showClose: true,
                    message: res.errMessage,
                    // type: "warning",
                });
            }


        },



        // 返回项目
        goToProject() {
            this.$router.replace({ path: "/index/user/User" });

        },

        //跨页多选
        getRowKey(row) {
            return row.id;
        },
        //被选中的数据
        handleSelectionChange(val) {
            this.selectOrderArr = val;
            // console.log("selectOrderArr", this.selectOrderArr);
        },


        //初识话数据

        getData(_page, _size, teamId) {
            this.page = _page


            this.size = _size
            this.teamId = teamId



            this.projectList(_page, _size, teamId)
        },




        //创建团队
        establishTeam() {

            this.createdTeamShow = true;
        },

        //取消创建
        cancelTeam() {
            this.createdTeamShow = false;
            this.projectName = ""
        },

        //确定创建
        createdTeamOk() {
            this.createdTeamShow = false;

            this.createUserGroup()



        },
        //创建团队接口
        async createUserGroup() {
            let data = {
                name: this.projectName
            }


            const res = await this.$https.createUserGroup(data)
            if (res.success == true) {
                this.$message('恭喜您创建成功');
                this.projectName = ""

                this.$parent.getUserGroupList()
            } else {

                this.$message(res.errMessage);
                this.projectName = ""

            }
        },





        //键盘事件
        Focus() {
            ISetting.isInput(true);
        },
        Blur() {
            ISetting.isInput(false);
        },

        handleSizeChange(val) {
            this.size = val;
            this.listLoading = true;
            this.projectList(1, val, this.teamId);
        },

        handleCurrentChange(val) {
            this.page = val;
            this.listLoading = true;
            this.projectList(val, this.size, this.teamId);
        },

        //已锁定
        locked() {
            this.$message({
                message: res.errMessage
                // type: "已有用户在编辑",
            });
        },
        //获取用户项目权限
        async getUserProjectAuthority(userId, groupId, row) {

            let data = {

                userId: userId,
                groupId: groupId

            }
            const res = await this.$https.getUserProjectAuthority(data)

            if (res.success == true) {

                localStorage.setItem("projectId", row.id);
                localStorage.setItem("projectName", row.name);
                this.$router.replace({ path: "/Home" });

                ISetting.exit();

                let list = res.data
                let userPermissionData = {}

                for (let index = 0; index < list.length; index++) {

                    userPermissionData[list[index]] = true

                }

                this.$store.commit("changeUserPermissionData", userPermissionData)

            } else {
                this.$message(res.errMessage)
            }



        },

        //打开
        handleEdit(row) {
            // this.getLocalType(row.id).then(() => {

            //   if (this.local == true) {
            // this.projectLocal(row.id).then(() => {






            let userId = localStorage.getItem("phone")
            let groupId = localStorage.getItem("teamId")


            this.getUserProjectAuthority(userId, groupId, row)

            //     });
            //   }
            // });
        },
        //删除
        handleDelete(row) {
            this.newFolder = false;
            this.setNewShow = false;
            this.deleteProject = true;
            this.deletId = row.id;
            this.deleteName = row.name;
            // this.$confirm(`确认删除${row.name}吗?`)
            //   .then(_ => {
            //     this.delProject(row.id);
            //   })
            //   .catch(_ => {});
        },

        deleteOk() {
            if (this.deleteName == this.inputDeleteName) {
                this.deleteProject = false;
                this.inputDeleteName = "";
                this.delProject(this.deletId).then(() => {
                    this.deletId = "";
                });
            } else {
                this.$message({
                    message: "你输入的项目名称与要删除的项目名称不符"
                    // type: "success",
                });
            }
        },
        deleteCancel() {
            this.deleteProject = false;
            this.deletId = "";
            this.deleteName = "";
            this.inputDeleteName = "";
        },

        //重命名
        rechristen(row) {
            this.deleteProject = false;
            this.newFolder = false;
            this.setNewShow = true;
            this.newNameId = row.id;

        },
        //取消重命名
        cancel() {
            this.setNewShow = false;
            this.newName = "";
        },
        closeChange() {
            this.setNewShow = false;
        },
        //确认重命名
        ok() {
            var newName = this.newName;
            // var containSpecial = "/^[\u4e00-\u9fa5a-z]+$/gi";
            var containSpecial = RegExp(
                /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
            );

            if (!newName) {
                this.$message({
                    message: "不能为空"
                    // type: "warning",
                });
            } else if (newName.length > 20) {
                this.$message({
                    message: "长度不能超过20"
                    // type: "warning",
                });
            } else if (containSpecial.test(newName)) {
                this.$message({
                    message: "输入不能为特殊字符"
                    // type: "warning",
                });
            } else {
                this.setNewShow = false;
                this.updateProjectName(this.newNameId).then(() => {
                    this.newName = "";
                });
            }
        },

        //点击创建项目
        PrjectChange() {



            this.createProject(this.teamId)
        },
        //创建项目
        createProject(createProjectId) {

            this.createProjectId = createProjectId
            this.deleteProject = false;

            this.newFolder = true;

            this.setNewShow = false;
        },


        //创建
        setChange() {
            var containSpecial = RegExp(
                /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
            );

            //特殊字符正则表达式

            // if (txt.test(this.projectName)){
            //     this.$message({
            //       message: "不能有特殊字符",
            //       type: "warning",
            //     });
            // }
            var projectName = this.projectName;

            if (this.projectName.trim() !== "") {
                if (!containSpecial.test(this.projectName)) {
                    if (this.privacyPolicy) {
                        this.newFolder = false;

                        this.addProject();

                    } else {
                        this.$message({
                            message: "请勾选新建项目条款"
                            // type: "warning",
                        });
                    }
                } else {
                    this.$message({
                        message: "项目名不能包含特殊字符串"
                        // type: "warning",
                    });
                }
            } else {
                this.$message({
                    message: "项目名字不能为空！"
                    // type: "warning",
                });
            }
        },
        //取消
        setCancel() {
            this.newFolder = false;
            this.projectName = "";
        },
        closeChange1() {
            this.newFolder = false;
        },

        //创建接口
        async addProject() {
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            });
            const res = await this.$https.addProject({
                // groupId: "c943db89-a6c1-4079-8fd4-06346dbab6b5",
                name: this.projectName,
                versionNum: "1.0.0",

                // groupName: localStorage.getItem("groupName"),
                groupId: this.createProjectId
            });

            if (res.errCode == null) {
                loading.close();
                this.$message({
                    message: "恭喜你，添加成功"
                    // type: "success",
                });
                this.projectName = "";
                this.projectList(this.page, this.size, this.teamId);


            } else {
                loading.close();

                this.projectName = "";

                var token = localStorage.getItem("token");
                if (token) {
                    this.$message({
                        message: res.errMessage
                        // type: "warning"
                    });
                }
            }
        },





        //列表回显接口
        async projectList(_page, _size, teamId) {
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            });

            const res = await this.$https.projectList({
                id: this.id,
                // groupId: "c943db89-a6c1-4079-8fd4-06346dbab6b5",
                page: _page,
                count: _size,
                // groupId:localStorage.getItem('groupName')
                groupId: teamId
            });

            if (res.errCode == null) {
                // console.log(res.data.relation,"res.relation");
                if (res.data.relation == true) {




                    this.listLoading = false;
                    loading.close();
                    this.tableData = res.data.data;
                    this.totals = res.data.total;
                } else {
                    //  loading.close();

                    this.$parent.defaultView()

                }


            } else {
                loading.close();

                var token = localStorage.getItem("token");
                if (token) {
                    this.$message({
                        message: res.errMessage
                        // type: "warning"
                    });
                }
            }
        },
        // 重命名接口
        async updateProjectName(id) {
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            });

            const res = await this.$https.updateProjectName({
                id: id,
                name: this.newName
            });

            if (res.errCode == null) {
                loading.close();
                this.newName = "";
                //获取数据
                this.projectList(this.page, this.size, this.teamId);

                this.$message({
                    message: "恭喜你，修改成功"
                    // type: "success",
                });
            } else {
                loading.close();
                var token = localStorage.getItem("token");
                if (token) {
                    this.$message({
                        message: res.errMessage
                        // type: "warning"
                    });
                }
            }
        },
        //删除接口

        async delProject(id) {
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            });
            const res = await this.$https.delProject({
                id: id
            });

            if (res.errCode == null) {
                loading.close();
                //获取数据
                this.projectList(this.page, this.size, this.teamId);

                this.$message({
                    message: "删除成功"
                    // type: "success",
                });
            } else {
                loading.close();
                var token = localStorage.getItem("token");
                if (token) {
                    this.$message({
                        message: res.errMessage
                        // type: "warning"
                    });
                }
            }
        },

        //项目上锁解锁接口
        async projectLocal(id) {
            const res = await this.$https.projectLocal({
                id: id,
                local: 1
            });
            if (res.errCode == null) {
            } else {
                var token = localStorage.getItem("token");
                if (token) {
                    this.$message({
                        message: res.errMessage
                        // type: "warning"
                    });
                }
            }
        },

        //获取锁状态
        async getLocalType(id) {
            const res = await this.$https.getLocalType({
                id: id
            });
            if (res.errCode == null) {
                this.local = true;
                return true;
            } else {
                this.local = false;

                var token = localStorage.getItem("token");
                if (token) {
                    this.$message({
                        message: res.errMessage
                        // type: "warning"
                    });
                }
                return false;
            }
        }
    },

    filters: {
        //过滤器时间
        TimeToTranslate(data) {
            if (value == 0) {
                return "时间信息错误!";
            } else {
                formatString = formatString || "YYYY-MM-DD HH:mm";
                return moment.unix(value).format(formatString); // 这是时间戳转时间
            }
        }
    }
};
</script>
<style scoped>
.container {
    width: 100%;
    height: 100%;
}

.header {
    width: 100%;
    height: 60px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header .pmImg {
    height: 40px;
    width: 150px;
    display: flex;
    justify-content: center;
    background: #fff;
    border-radius: 20px;
    margin: 0 20px;

    align-items: center;
    line-height: 25px;
    color: #333;
    cursor:pointer ;
}

.header .pmImg img {
    width: 15px;
    /* height: 19px; */
    margin-right: 10px;
}

/deep/ .el-table__body-wrapper {
    background: #eff3f4;
}

.header .add {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.header .add img {
    width: 17px;
    height: 16px;
}

.tableTop {
    width: 100%;
    height: 60px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.tableTop .pmImg {
    height: 40px;
    width: 150px;
    display: flex;
    justify-content: center;
    background: #fff;
    border-radius: 20px;
    margin: 0 20px;

    align-items: center;
    line-height: 25px;
    color: #333;
    cursor:pointer ;
}



.main {
    width: 80%;
    height: calc(100% - 80px);
    margin: auto;
}

.el-table {
    border: 2px solid rgba(255, 255, 255, 0.7);
    box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
    border-radius: 10px;
}

.el-table>>>tr {
    background: #eff3f4 !important;
}

.popup {
    width: 500px;
    height: 240px;
    position: fixed;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
    z-index: 9999999;
}

.popup1 {
    width: 500px;
    height: 200px;
    position: fixed;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
    z-index: 9999999;
}

.popup .new {
    width: 90%;
    height: 50px;
    margin: auto;
    line-height: 50px;
    text-align: left;
    font-weight: bolder;
    font-size: 14px;
    /* padding-left: 20px; */
}

.popup .name {
    width: 90%;
    height: 40px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    padding-right: 25px;
}

.popup .name p {
    width: 100px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    font-size: 12px;
    font-weight: bolder;
}

.popup .close {
    position: absolute;
    right: 20px;
    top: 0px;
    cursor: pointer;
}

.popup .close img {
    width: 10px;
    height: 10px;
}

.popup .ipt_box {
    width: 360px;
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup .ipt_box .input {
    width: 350px;
    height: 35px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0);
    padding-left: 15px;
}

.footer {
    width: 100%;
    height: 100px;
    position: fixed;
    bottom: 0;
    font-size: 12px;
    z-index: 999999999;
}

.footer .privacy {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.footer .set {
    width: 420px;
    height: 50px;
    line-height: 50px;
    border-radius: 10px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    margin: 15px auto;
}

.enter {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    font-size: 12px;
    display: flex;
}

.enter .set {
    width: 200px;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
}

.verify {
    background-color: #0560fd;
    color: #fff;
    margin-left: 20px;
    margin-top: 15px;
    margin-left: 35px;
}

.cancel {
    color: #999;
    background: #ffffff;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    margin-top: 15px;
    margin-left: 30px;
}

.input:checked {
    background: #1673ff;
}

.input {
    width: 18px;
    height: 18px;
    outline: none;
    background-color: rgba(125, 128, 133, 0.4);
    border: solid 1px #dddddd;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-size: 0.8rem;
    padding: 0;
    position: relative;
    display: inline-block;
    vertical-align: top;
    cursor: default;
    -webkit-appearance: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-transition: background-color ease 0.1s;
    transition: background-color ease 0.1s;
    outline: none;
}

.input:checked::after {
    content: "";
    top: 3px;
    left: 4px;
    position: absolute;
    background: transparent;
    border: #fff solid 2px;
    border-top: none;
    border-right: none;
    height: 4px;
    width: 6px;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.el-button {
    background: #eff3f4 !important;
    border: none;
}

.el-button:hover {
    background: none !important;
}

.add_text {
    float: right;
    margin: 0 10px;
    line-height: 30px;
}

.paging {
    margin-top: 20px;
}

.totalNumber {
    float: left;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 30px;
}

.delete {
    width: 350px;
    height: 150px;
}

.deleteName {
    width: 100%;
    height: 46px;
    line-height: 46px;
    text-align: center;
    font-size: 12px;
    font-weight: bolder;
    margin-top: 25px;
}

.deleteOK {
    background-color: #0560fd;
    color: #fff;
    margin-left: 20px;
    margin-top: 15px;
    margin-left: 40px;
}

.deleteCancel {
    color: #999;
    background: #ffffff;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 30px;
}

.myset {
    width: 220px;
    height: 35px;
    line-height: 35px;
    border-radius: 8px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
}

.myVerify {
    background-color: #0560fd;
    color: #fff;
    margin-left: 20px;
    margin-top: 15px;
    margin-left: 35px;
}

.myCancel {
    color: #999;
    background: #ffffff;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    margin-top: 15px;
    margin-left: 30px;
}

/*  滚动条的宽度 */
.main /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* 滚动条的滑块 */
.main /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 5px #69a0fe;
    background: #69a0fe;
    border-radius: 3px;
}

/* 创建团队部分 */
.createdTeam {
    width: 500px;
    height: 200px;
    position: fixed;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
    z-index: 9999999;
}

.createdTeam .close {
    position: absolute;
    right: 20px;
    top: 0px;
    cursor: pointer;
}

.createdTeam .close img {
    width: 10px;
    height: 10px;
}

.createdTeam .ipt_box {
    width: 360px;
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.createdTeam .ipt_box .input {
    width: 350px;
    height: 35px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0);
    padding-left: 15px;
}

.createdTeam .name {
    /* margin-top: 50px; */

    width: 90%;
    height: 120px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    padding-right: 25px;
}

.createdTeam .name p {
    width: 100px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    font-size: 12px;
    font-weight: bolder;
}
</style>