
<template>
    <div class="box">
        <div class="FurnitureDesignData">
            <div class="title">
                <el-button type="primary">选择商品</el-button>
            </div>
                <div class="name">
                    商品名称：<el-input v-model="productName" placeholder="请输入内容"></el-input>
                </div>
          
            <div class="FurnitureDesignImg">
                <div class="imgTop">
                    上传商品图
                    <el-button type="primary">点击上传</el-button>

                    <img src="" alt="">

                </div>
            </div>

            <div class="unitPrice">
                官方导购价：<el-input v-model="priceGuide" placeholder="请输入内容"></el-input>
            </div>
            <div class="address">
                商品第三方链接：<el-input v-model="partyJump" placeholder="请输入内容"></el-input>
            </div>
            <div class="introduce">

                <div class="introduceTitle">
                    商品介绍：
                    <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="textarea">
                    </el-input>
                </div>
            </div>
            <div class="verify">
                <el-button type="primary">确认</el-button>
            </div>
        </div>
        <div class="tree">

        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            productName:"",
            priceGuide:"",
            partyJump:"",
            textarea:"",
        }
    }
}
</script>

<style lang="less" scoped>
.FurnitureDesignData{
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    flex-direction:column;
    .FurnitureDesignImg{
        margin-top: 20px;
    }  





}
</style>