<template>
  <div class="box">

    <div class="title">

      <span class="name">上传效果图</span>
      <el-button type="primary" @click="btnChange()">选择上传</el-button>
      <input type="file" value="选择图片" ref="file" @change="fileChange($event)" accept="image/png, image/jpeg, image/jpg" style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0"/>


    </div>
    <div class="itemList">
      <div class="item" v-for="item in effectPicture" :key="item.id">
        <img :src="item.url" alt="">
        <i class="el-icon-close close" @click="DeletEffectPicture(item)" ></i>
      </div>
    </div>
  </div>
</template>

<script>

import { OssManager } from "../../../threejs/network/OssManager";


 import {MainObjects} from "../../../threejs/common/MainObjects";
export default {
  data() {
    return {

      effectPicture: [
       

      ],
      headPortrait1:"",
      filestate:false,
    }
  },
  methods:{
    
    getNum(num) {
      var random = Math.floor(
        (Math.random() + Math.floor(Math.random() * 9 + 1)) *
          Math.pow(10, num - 1)
      );
      return random;
    },
    fileChange(e){
      var _this = this;

          
      let https = MainObjects.Network.m_http;

    //   let getHtttps=MainObjects.Network.m_http;
      
      const files = e.target.files
      console.log(files,"files")

      let data = new OssManager(MainObjects.Network.m_bucketName);

      let url =   data.getCloudShopFloder("basicInformation", this.reNum)






      function uploadFinish(list) {
        let urls = https + url + files[0].name;
        _this.headPortrait1 = urls;
        _this.filestate = false;

        let data={
          id:_this.reNum,
          url:urls,
          naem: files[0].name
        }
        _this.effectPicture.push(data)
      }

      data.f_finishCallback = uploadFinish;


      

      data.upload(files[0],url)

    },

    btnChange(){
      this.$refs.file.click()
    },
    DeletEffectPicture(item){

      this.effectPicture.splice(this.effectPicture.indexOf(item), 1)

    
  },
  }

  
}
</script>

<style lang="less" scoped>
.box {
  width: 100%;

  .title {
    width: 80%;
    font-size: 18px;
    margin-top: 20px;
    color: #000;
    margin: 0 auto;
    margin-top: 20px;
    line-height: 40px;
    display: flex;
    justify-content: space-between;

    .titleName {
      margin-right: 60px;
    }


  }

  .itemList {
    width: 100%;
    height: 700px;
    margin-top: 30px;
    overflow-y: auto;


    .item {
      width: 200px;
      height: 200px;
      background-color: #fff;
      box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
      margin: 0 auto;
      margin-top: 30px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      .close {
        position: absolute;
        top: 5px;
        right: 5px;
        color: #000;
      }
    }
  }

}


.itemList::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.itemList::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px #69a0fe;
  background: #69a0fe;
}

.itemList::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #e9f1fe;
}
</style>