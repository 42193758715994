<template>
  <div class="box">
    <div class="content">
      <div class="title">

        <span class="titleName">
          <el-select v-model="value" placeholder="  店铺类型：" @change="storeEnvironmentList()">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

        </span>
        <el-button type="primary" @click="uploading()">自定义上传</el-button>
        <input type="file" value="选择图片" ref="file" @change="fileChange($event)"
          accept="image/png, image/jpeg, image/jpg" style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0" />

      </div>
      <div class="list">


        <div class="mapList">
          <div class="item" v-for="item in mapList" :key="item.id">
            <img :src="item.image" alt="">
            <i class="el-icon-close close"></i>

          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      mapList: [

      ],
      options: [],


    }
  },
  mounted() {
    this.storePropertyList()

    this.storeEnvironmentList()
  },
  methods: {

    getNum(num) {
      var random = Math.floor(
        (Math.random() + Math.floor(Math.random() * 9 + 1)) *
        Math.pow(10, num - 1)
      );
      return random;
    },
    fileChange(e) {

      var _this = this;

      let https = MainObjects.Network.m_http;

      //   let getHtttps=MainObjects.Network.m_http;

      const files = e.target.files
      console.log(files, "files")

      let data = new OssManager(MainObjects.Network.m_bucketName);

      let url = data.getCloudShopFloder("basicInformation", this.reNum)


      let urls = https + url + files[0].name;
      function uploadFinish(list) {
        let urls = https + url + files[0].name;
        _this.map = urls + "?time=" + this.reNum;


        _this.filestate = false;
      }
      data.f_finishCallback = uploadFinish;
      data.upload(files[0], url)

    },



    uploading() {
      this.$refs.file.click()
    },



    async storeEnvironmentList() {
      let data = {
        storeProperty: this.value
      }
      const res = await this.$https.storeEnvironmentList(data)
      if (res.success == true) {
        this.mapList = res.data

      }


    },


    async storePropertyList() {
      let data = {}
      const res = await this.$https.storePropertyList(data)

      if (res.success == true) {
        console.log("获取成功", res.data);

        let data = res.data;
        let sectionList = [];

        for (let i in data) {
          let obg = {
            value: i,
            label: data[i]
          };
          sectionList.push(obg);
        }

        this.options = sectionList;
        // console.log(sectionList, "this.sectionList");
      }




    }
  }

}
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;

  .content {

    .title {
      width: 80%;
      margin: 0 auto;

      font-size: 18px;
      margin-top: 20px;
      color: #000;
      display: flex;

      .titleName {
        margin-right: 10px;

      }

    }

    .list {
      height: 760px;
      overflow-y: auto;
      width: 100%;


      .mapList {
        width: 100%;
        margin: 0 auto;
        overflow-y: auto;

        .item {
          width: 200px;
          height: 200px;
          background-color: #fff;
          box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
          margin: 0 auto;
          margin-top: 30px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
          }

          .close {
            position: absolute;
            top: 5px;
            right: 5px;
            color: #000;
          }
        }
      }
    }

  }
}

.mapList::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.mapList::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px #69a0fe;
  background: #69a0fe;
}

.mapList::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #e9f1fe;
}
</style>