class CameraData{
    constructor() {
        this.m_id = null;
        this.m_FOV=0;
        // 垂直角度
        this.m_vertical=90;
        // 水平视角
        this.m_horizontal=90;
        this.m_initialCamera=false;
        this.m_name="";
        this.m_img="";
        this.m_pos = {}
    }
    getCameraData(){
        let obj={
            id:this.m_id,
            FOV: this.m_FOV,
            vertical:this.m_vertical,
            horizontal:this.m_horizontal,
            initialCamera: this.m_initialCamera
        }
        return obj;
    }

    setCameraData(data){

        this.m_FOV=data.FOV;
        this.m_vertical=data.vertical;
        this.m_horizontal=data.horizontal;
        this.m_pos.x = data.position.x
        this.m_pos.y = data.position.y
        this.m_pos.z = data.position.z

    }
    
    
}
export { CameraData };
