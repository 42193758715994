<template>
  <!-- 登录 -->
  <div class="user-loginCon">
    <div class="loginCenter">
      <div class="loginCenter-left">
        <!-- <div class="logo">
                
            </div> -->
      </div>

      <div class="loginCenter-right">

        <div class="logo">

        </div>
        <div class="loginCenter-right-conter">


          <p class=" login-num">
          <section class="num">
            <el-input v-model="loginAccount" maxlength="11" placeholder="请输入手机号"></el-input>
          </section>
          </p>
          <div class="inputstyle">
            <p class=" login-verifyCode">
            <section class="loginCode">
              <el-input v-model="verifyCode" maxlength="6" placeholder="请输入验证码">
                <el-button slot="append" v-if="codeShow" @click="getCode">获取验证码</el-button>

                <template slot="append" v-else>{{ count }}s</template>

              </el-input>

              <div class="footer">
                <div class="privacy">
                  <input type="checkbox" v-model="privacyPolicy" class="input" />
                  <p>勾选表示同意

                    <router-link to="/termsOfService">
                      【服务条款】
                    </router-link>
                    与
                    <router-link to="/privacyPolicy">
                      【隐私政策】
                    </router-link>


                  </p>
                </div>

              </div>


            </section>



            </p>
          </div>

          <div class="onlogin" @click="loginClick" ref="login">
            <div class="onlogin-text"> 登录</div>
            <!-- <el-button type="primary" size="medium" @click="loginClick">登录</el-button> -->
          </div>

        </div>
      </div>


    </div>


  </div>
</template>

<script>
import utils from "../../method/anti-shake";

export default {
  name: "login",
  // 自定义指令
  directives: {},
  data() {
    return {
      //账号
      loginAccount: "",
      //验证码
      verifyCode: "",
      //是否发送了验证码
      codeShow: true,
      //倒计时
      count: "",
      operation: new Map(),
      //是否勾选
      privacyPolicy: false,
    };
  },
  components: {},
  created() {
    // localStorage.clear();
    let token = localStorage.getItem("token");

    if (token && token != "" && token != undefined) {
      this.$router.replace("/index/user/User");
    }

    let that = this;
  },
  mounted() {
    const self = this;
    //  localStorage.clear()

    document.onkeypress = function (e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        self.loginClick(); // 登录方法名
        return false;
      }
    };

    document.title = '艺搭引擎';

  },
  beforeDestroy() {
    // document.onkeypress=null
  },
  methods: {
    //点击获取验证码
    getCode() {
      if (this.loginAccount == "") {
        this.$message({
          showClose: true,
          message: "手机号码为空",
          // type: "warning",
        });
        return false;
      } else if (!/^1[3456789]\d{9}$/.test(this.loginAccount)) {
        // this.colors = { borderColor: "#ff0000" };
        // this.modalNumShow = !this.modalNumShow;
        // this.modalNum = "请填写正确手机号";
        this.$message({
          showClose: true,
          message: "请填写正确手机号",
          // type: "warning",
        }); 
        
        return false;
      } else {
        this.codeShow = false;
        const TIME_COUNT = 60;
        this.phoneArgument();
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.codeShow = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.codeShow = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      }
    },

    //   验证码获取
    async phoneArgument() {
      const res = await this.$https.getPhoneVerificationCode({
        phone: this.loginAccount,
      });
      var cotionCode = res.errCode;
      if (cotionCode == null) {
        this.$message({
          showClose: true,
          message: "获取验证码成功！",
          // type: "success",
        });
      } else if (cotionCode == 401) {
        // this.colors = { borderColor: "#ff0000" };
        // this.modalNumShow = !this.modalNumShow;
        // this.modalNum = "手机号格式错误,请填写正确手机号！";
        this.$message({
          showClose: true,
          message: "请填写正确手机号",
          // type: "warning",
        });

        return false;
      } else {
        this.$message({
          showClose: true,
          message: res.errMessage,
          // type: "warning",
        });
        return false;
      }
    },

    //登录校验
    loginClick: utils.debounce(function () {

      // for test
      var _this = this;
      //  localStorage.setItem('token','eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiIwYzhhODdkMy1mNjE4LTQxMmItYmE4OC0wOWExMjIyMyIsIm5pY2tOYW1lIjoi5r2Y56eL5aicIiwic2NvcGUiOlsidGVzdCJdLCJpZCI6IjBjOGE4N2QzLWY2MTgtNDEyYi1iYTg4LTA5YTEyMjIzIiwiZXhwIjoxNTczMTkyMzg5LCJhdXRob3JpdGllcyI6IlJPTEVfMTU2OTY1MzA5MTg4OSxST0xFX0FVVEhPUklUWV9NQU5BR0VfUEFHRSxST0xFX0FVVEhPUklUWV9NT0RVTEVfVklFVyxST0xFX0RFQUxfUk9MRSxST0xFX0RFQUxfTUVNQkVSLFJPTEVfMTU2OTY1MzA5MTg4OSxST0xFX01BTkFHRV9VU0VSX1BBR0UsUk9MRV9VU0VSX01PRFVMRV9WSUVXIiwianRpIjoiNDA4OGY4MDMtYmQ5Zi00ODM0LTgxNmItM2RmODA2YzA1ZTE2IiwiY2xpZW50X2lkIjoiY2xpZW50MSJ9.P-lJTTrTv5wjX52adSAynYCZxuPbCUIPTtMHtU8xWv8ZLmJTkM03wMgm_hmL7xZHRolM1RAXvLkAUL7GXDhP8Twt2bMScTi1b3DVd8d7Z5zjH_2k4IxKGGY1IyidTHaEn1FImM3XRrAqixFT-leAFWjoRSP1WvUgZ-qGs1Vio5D-vD-a5rPBfMshGBgYqSKtrMvBtpHZ_Aut_KfNDepdt-FNYJvhGoyoAfpe6T0J02reCdnGR9jxtGLZywEFzvJd8qf0yp7jsFZ7vVZQs_fMiH7p_AKd85suVAz_mAt5aqUPV16Z-cVud323J4ue03y-8TOQHkyNdVjHB5BW-qcGYw')
      if (!/^1[3456789]\d{9}$/.test(this.loginAccount)) {
        this.colors = { borderColor: "#ebeef3" };
        this.modalNumShow = !this.modalNumShow;
        this.modalNum = "请填写正确手机号";
        this.$message({
          showClose: true,
          message: "请填写正确手机号",
          // type: "warning",
        });

        return false;
      } else if (this.loginAccount == "") {
        this.$message({
          showClose: true,
          message: "请填写手机号",
          // type: "warning",
        });
        return false;
      } else if (this.verifyCode == "") {
        this.$message({
          showClose: true,
          message: "请填写验证码",
          // type: "warning",
        });
        return false;
      } else if (!/^1[3456789]\d{9}$/.test(this.loginAccount)) {
        this.$message({
          showClose: true,
          message: "请填写手机号",
          // type: "warning",
        });
        return false;
      } else if (this.privacyPolicy == false) {
        this.$message({
          showClose: true,
          message: "请勾选用户协议和隐私协议",
          // type: "warning",
        });
        return false;
      } else {
        this.loginArgument();
      }
    }),

    //登录接口
    async loginArgument() {
      var _this = this;
      const res = await this.$https.phoneLogin({
        phone: this.loginAccount,
        code: this.verifyCode,
      });

      var code = res.errCode;
      var outCode = res.success;
      if (outCode == true) {
        if (code === null) {
          this.$message({
            showClose: true,
            message: "登录成功",
            // type: "success",
          });
          // if (res.data.access_token != null) {

          if (res.success == true) {






            // var gainTokoen = res.data.access_token; //获取token
            var gainTokoen = res.data.token; //获取token
            localStorage.setItem("token", gainTokoen);
            var loginAccount = this.loginAccount;
            localStorage.setItem("phone", loginAccount);
            var groupName = res.data.groupName;
            localStorage.setItem("groupName", groupName);
            var userName = res.data.userName;
            localStorage.setItem("userName", userName);

            //存储头像

            var image = res.data.image;
            localStorage.setItem("image", image),
              // var cutString = gainTokoen.split(".")[1];
              // var baseString = Base64.decode(cutString); //解码64
              // var changeObj = JSON.parse(baseString); //转换对象
              // // 存储
              // localStorage.setItem("token", res.data.access_token);
              // localStorage.setItem("token2", res.data.refresh_token);
              // localStorage.setItem("nickName", changeObj.nickName); // 用户名
              // localStorage.setItem("ID", changeObj.user_name); // 用户id

              //存储默认团队id
              localStorage.setItem("teamId", res.data.groupId)
            //存储默认【团队id的权限
            localStorage.setItem("teamLevel", res.data.level)

            //存储userSig
            localStorage.setItem("userSig", res.data.userSig)

            this.$tim.login({ userID: loginAccount, userSig: res.data.userSig });

            this.$store.commit("changeUserRank", res.data.level)

            this.$router.replace("/index/user/User");



            //获取权限
            // _this.getRoleData();
          }
        }








        else if (outCode == false) {
        }
      } else if (code === "400") {
        this.$message({
          showClose: true,
          message: res.errMessage,
          // type: "warning",
        });
      } else if (code == 413) {

        this.$confirm("该账号已关闭,是否恢复数据?", "提示", {
          confirmButtonText: "恢复数据",
          cancelButtonText: "清空数据",
          type: "warning"
        })
          .then(() => {


            this.whetherRecover(this.loginAccount, 0)




          })
          .catch(() => {
            this.whetherRecover(this.loginAccount, 1)

          });





      }






      else if (outCode == false) {
        if (code === "400") {
          // this.colorCode = { borderColor: "#ff0000" };
          // this.modalCodeShow = true;
          // this.modalCode = "请输入验证码";
          this.$message({
            showClose: true,
            message: res.errMessage,
            // type: "warning",
          });

          return false;
        } else if (code === "401") {
          this.colorCode = { borderColor: "#ff0000" };
          this.modalCodeShow = true;
          this.modalCode = "您输入的验证码不正确!";
          return false;
        } else if (code === "402") {
          this.$message({
            showClose: true,
            message: res.errMessage,
            // type: "warning",
          });
        } else if (code === "403") {
          this.$message({
            showClose: true,
            message: res.errMessage,
            // type: "warning",
          });
        } else if (code === "405") {
          this.$message({
            showClose: true,
            message: res.errMessage,
            // type: "warning",
          });
        } else if (code === "406") {
          this.$message({
            showClose: true,
            message: res.errMessage,
            // type: "warning",
          });
        } else if (code === "407") {
          this.$message({
            showClose: true,
            message: res.errMessage,
            // type: "warning",
          });
        } else if (code === "408") {
          this.$message({
            showClose: true,
            message: res.errMessage,
            // type: "warning",
          });
        } else if (code === "409") {
          this.$message({
            showClose: true,
            message: res.errMessage,
            // type: "warning",
          });
        } else if (code === "500") {
          this.$message({
            showClose: true,
            message: res.errMessage,
            // type: "warning",
          });
        }
      }
    },
    //账号被删除是否恢复数据
    async whetherRecover(phone, isRecover) {
      let data = {
        phone: phone,
        isRecover: isRecover
      }
      const res = await this.$https.whetherRecover(data)
      if (res.success == true) {
        // var gainTokoen = res.data.access_token; //获取token
        var gainTokoen = res.data.token; //获取token
        localStorage.setItem("token", gainTokoen);
        var loginAccount = this.loginAccount;
        localStorage.setItem("phone", loginAccount);
        var groupName = res.data.groupName;
        localStorage.setItem("groupName", groupName);
        var userName = res.data.userName;
        localStorage.setItem("userName", userName);

        //存储头像

        var image = res.data.image;
        localStorage.setItem("image", image),
          // var cutString = gainTokoen.split(".")[1];
          // var baseString = Base64.decode(cutString); //解码64
          // var changeObj = JSON.parse(baseString); //转换对象
          // // 存储
          // localStorage.setItem("token", res.data.access_token);
          // localStorage.setItem("token2", res.data.refresh_token);
          // localStorage.setItem("nickName", changeObj.nickName); // 用户名
          // localStorage.setItem("ID", changeObj.user_name); // 用户id

          //存储默认团队id
          localStorage.setItem("teamId", res.data.groupId)
        //存储默认【团队id的权限
        localStorage.setItem("teamLevel", res.data.level)

        //存储userSig
        localStorage.setItem("userSig", res.data.userSig)

        this.$tim.login({ userID: loginAccount, userSig: res.data.userSig });

        this.$store.commit("changeUserRank", res.data.level)

        this.$router.replace("/index/user/User");


      } else {
        this.$message({
          showClose: true,
          message: res.errMessage,
          // type: "warning",
        });
      }

    },


    //获取权限
    getRoleData() {
      this.roleArgument();
    },
    //权限接口
    async roleArgument() {
      var _this = this;
      const res = await this.$https.getOperationMessageById({
        operationId: localStorage.getItem("ID"),
      });

      if (res.errCode == null) {
        // 权限接口

        var authorityCODOList = res.data.authorityCODOList;
        "router_roles", JSON.stringify(authorityCODOList);
        var All_roles = [];
        authorityCODOList.map((item, index) => {
          if (item.authorityDesc != null && item.authorityDesc != undefined) {
            All_roles.push(...item.authorityDesc.split(","));
          }
        });
        localStorage.setItem("All_roles", All_roles);
        _this.$setRouter();
        // setTimeout(() => {
        //   _this.$router.push({path: '/index/user'})
        // }, 100)
        localStorage.setItem("user_nickName", res.data.operationDO.name);
        // var userName = res.data.data.operationDO.name;
        // this.sysUserName = userName;
        var data = res.data;
        var userName = res.data.operationDO.name;
        // this.sysUserName = userName;
        data.authorityCODOList.map((item, index) => {
          // this.operation.set(item.authorityName, true);
          if (item.authorityDesc != null && item.authorityDesc != undefined) {
            var authorityDesc = item.authorityDesc.split(",");
            for (var i in authorityDesc) {
              this.operation.set(authorityDesc[i], true);
            }
          }
        });
        var operationStr = JSON.stringify(this.operation);
        localStorage.setItem("operation", operationStr);
        // this.roleMethods()
        // this.$router.push({path: '/'})
      } else {
        this.$message({
          message: res.errMessage,
          // type: "warning",
        });
      }
    },
  },
};
</script>
<style scoped>
.user-loginCon {
  height: 100%;
  /* background:url(../../assets/Login/background.png);
    background-size: 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    z-index: 1; */
}

.loginCenter {
  position: fixed;
  width: 1200px;
  height: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  /* border: 3px solid antiquewhite; */


  border-radius: 20px;
}

.loginCenter-left {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
  background: url(../../assets/Login/background.png);
  background-size: 100%;
  background-repeat: no-repeat;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.logo {
  position: absolute;
  top: 14%;
  left: 50%;
  width: 150px;
  height: 100px;
  /* background-color: red; */

  background-image: url("../../assets/Login/icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  transform: translate(-50%, -50%);
}

.loginCenter-right {
  position: relative;
  float: right;
  width: 50%;
  height: 100%;
  /* background-color:blue;  */
}

.loginCenter-right-conter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-num {
  margin-top: 50px;
  width: 470px;
  margin: 30px auto;
}

.login-verifyCode {
  margin-top: 30px;
  width: 470px;
  margin: 30px auto;
  border-radius: 20px;
}

.onlogin {
  position: relative;
  margin-top: 50px;
  width: 470px;
  height: 70px;
  background: #0560fd;
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.2);
  border-radius: 10px;
  cursor: pointer;
}

.onlogin-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 57px;
  height: 27px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
}

.login-num /deep/ .el-input__inner {
  height: 60px;
  border-radius: 10px;
}

.login-verifyCode /deep/ .el-input__inner {
  height: 60px;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.login-verifyCode /deep/ .el-input-group__append {
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.footer {
  margin-top: 20px;
}

.footer .privacy {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footer .set {
  width: 420px;
  height: 50px;
  line-height: 50px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  background-color: #0560fd;
  cursor: pointer;
  margin: 15px auto;
}
</style>
