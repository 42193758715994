<template>
  <!-- 学习视频列表 -->
  <div class="box">
    <div class="topTitle">
      <div class="groupByTitle">主题分类
        <div class="shutter"></div>

      </div>
    </div>

    <div class="groupBy">
      <div class="groupByContent">
        <div class="groupByContentItem" :class="{ 'selectGroupByContentItem': item.subjectId == GroupId }"
          v-for="item in jectList" :key="item.subjectId" @click="selectGroup(item.subjectId)">
          {{ item.subject }}【{{ item.number }}】</div>
        <!-- <div class="groupByContentItem">店铺搭建【99】</div>
        <div class="groupByContentItem">运动轨迹【99】</div>
        <div class="groupByContentItem">游戏场景【99】</div>
        <div class="groupByContentItem">人物动画【99】</div>
        <div class="groupByContentItem">DIY编辑器【99】</div>-->
      </div>
    </div>

    <div class="VideoListBox">
      <div class="Videotype">
        <span @click="setSort(0)" :class="{ 'selectVideotype': sort == 0 }">全部</span>
        |
        <span @click="setSort(2)" :class="{ 'selectVideotype': sort == 2 }">最多播放</span>
        |
        <span @click="setSort(1)" :class="{ 'selectVideotype': sort == 1 }">最新上传</span>


      </div>

      <div class="VideoListContent" v-if="videoList.length > 0">
        <div class="VideoListContentItem" v-for="item in videoList" :key="item.id"
          @click="clickVideoVisible(item.videoUrl, item.id)">

          <div class="VideoListContentItemTop">
            <img :src="item.image" alt />
            <div class="VideoListContentItemTitle"></div>
            <div class="VideoListContentItemTitleText">{{ item.videoName }}</div>
            <div class="VideoListContentItemMasking"></div>
            <div class="leftBottom">
              <div class="icon">
                <img src="../../assets/OfficialWebsite/playQuantity.png" alt />
              </div>
              <span>{{ item.playCount }}</span>
            </div>
          </div>

          <div class="VideoListContentItemBottom">
            <div class="VideoListContentItemBottomText">最近观看</div>
            <div class="userList">
              <div class="user" v-for="i in item.lookPeople " :key="i.id">
                <div class="userTop">
                  <img :src="i.image" alt />
                </div>
                <div class="userName">{{ i.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="noMore">
        <div class="quexing">

          <img src="../../assets/OfficialWebsite/quexing.png" alt="">
          <div class="text">
            没有更多视频
          </div>

        </div>
      </div>

    </div>

    <div class="videoCheck" v-show="videoVisible" @click.self="closeVideoVisible()">


    </div>

    <div class="videoBox" v-show="videoVisible">
      <video :src="videoUrl" ref="myVideo" loop autoplay controls></video>

      <!-- <div class="close"> -->
      <i class="el-icon-circle-close close" @click="closeVideoVisible()">

      </i>

      <!-- </div> -->
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      videoVisible: false,
      videoList: [

      ],
      //主题列表
      jectList: [],
      //选中的
      GroupId: 1,

      sort: "0",
      videoUrl: "",

    };
  },
  mounted() {
    let GroupId = this.$route.query.id
    if (GroupId) {

      this.GroupId = this.$route.query.id
    }


    //获取主题列表
    this.getSubjectList();

    this.getVideoDate(1, this.GroupId, this.sort)
  },
  methods: {
    clickVideoVisible(videoUrl, id) {
      this.videoVisible = true;
      this.videoUrl = videoUrl
      let token = localStorage.getItem('token')
      if (token) {
        let name = localStorage.getItem("image")
        let image = localStorage.getItem("userName")

        this.myVideoClick(id, name, image)
      }


    },
    closeVideoVisible() {
      this.videoVisible = false;

      this.getVideoDate(1, this.GroupId, this.sort)
      this.$refs.myVideo.pause();


    },
    handleClose() {
      this.videoVisible = false;
    },
    //设置排序类型
    setSort(sort) {
      this.sort = sort
      this.getVideoDate(1, this.GroupId, this.sort)

    },

    //获取主题列表
    async getSubjectList() {
      const res = await this.$https.subjectList({});
      if (res.success == true) {
        this.jectList = res.data;
      }
    },
    //点击列表主题
    selectGroup(GroupId) {
      this.GroupId = GroupId
      this.getVideoDate(1, this.GroupId, this.sort)

    },

    //获取官网视频数据
    async getVideoDate(plateId, subjectId, sort) {

      let data = {
        plateId: plateId,
        subjectId: subjectId,
        sort: sort == 0 ? 1 : sort
      };
      const res = await this.$https.videoDate(data);
      if (res.success == true) {
        this.videoList = res.data
      }
    },
    //视频点击
    async myVideoClick(id, name, image) {
      let data = {
        id: id,
        name: name,
        image: image
      };
      const res = await this.$https.videoClick(data);
    },

  }
};
</script>

<style scoped>
.box {
  background: #fff;
  padding-top:100px;
}

.topTitle {
  width: 70%;
  margin: 0 auto;
  /* margin-top: 41px; */
}

.groupByTitle {
  width: 300px;
  height: 50px;
  background: #000000;
  border-radius: 6px 6px 0px 0px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  position: relative;
  cursor: pointer;

}

.shutter {
  width: 100%;
  height: 3px;
  background: #000;
  position: absolute;
  bottom: -2px;
}

.groupBy {
  width: 100%;
  height: 62px;
  background: #000000;
  /* border: 1px solid #1066FD; */
  box-shadow: 0px 0px 9px 0px rgba(33, 35, 49, 0.1);
  border: 1px solid #1066FD;
  border-left: 0;
  border-right: 0;

}

.groupBy .groupByContent {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.groupBy .groupByContent .groupByContentItem {
  height: 62px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999;
  line-height: 62px;
  text-align: center;
  width: 200px;
  cursor: pointer;
}

.selectGroupByContentItem {
  height: 62px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff !important;
  line-height: 62px;
  text-align: left;
  /* width: 250px; */
  border-bottom: 2px solid #1066FD;

}

/* 视频列表部份 */

.VideoListBox {
  width: 70%;
  margin: 0 auto;
}

.Videotype {
  margin: 40px 0;
  width: 233px;
  height: 21px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000;
  /* line-height: 88px; */
  cursor: pointer;
}

.selectVideotype {
  color: #1066fd;

}

.VideoListContent {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

/* 一个视频 */
.VideoListContentItem {
  width: 20%;
  height: 330px;
  /* background: red; */
  margin-bottom: 54px;
  margin-right: 66px;

  background: #ffffff;
  box-shadow: 0px 0px 9px 0px rgba(33, 35, 49, 0.08);
  border-radius: 6px;
}

.VideoListContentItemTop {
  height: 170px;
  width: 100%;
  overflow: hidden;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  position: relative;
}

.VideoListContentItemTop img {
  width: 100%;
}

/* 设置文字 */
.VideoListContentItemTop .VideoListContentItemTitleText {
  margin-left: 10px;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  z-index: 9;
}

.VideoListContentItemTop .VideoListContentItemTitle {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;

  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  /* background: rgba(0, 0, 0, 0.5); */
  z-index: 99;
}

.VideoListContentItemTop .VideoListContentItemMasking {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;

  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
  /* background: rgba(0, 0, 0, 0.5); */
  z-index: 99;
}

.VideoListContent .VideoListContentItem .VideoListContentItemTop .leftBottom {
  position: absolute;
  right: 10px;
  bottom: 10px;
  height: 20px;
}

.VideoListContent .VideoListContentItem .VideoListContentItemTop .leftBottom .icon {
  display: inline-block;
  width: 20px;
  /* height: 12px; */
  margin-right: 4px;
}

.VideoListContent .VideoListContentItem .VideoListContentItemTop .leftBottom .icon img {
  width: 100%;
}

.VideoListContent .VideoListContentItem .VideoListContentItemTop .leftBottom span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d6d6d6;
  /* line-height: 88px; */
}

.VideoListContentItemBottom {
  height: 154px;
  width: 100%;
}

.VideoListContentItemBottom .VideoListContentItemBottomText {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  margin-top: 16px;
  margin-left: 10px;
}

.VideoListContentItemBottom .userList {
  /* background: #1066fd; */
  height: 80px;
  width: 250px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  /* justify-content: space-between; */
}

.VideoListContentItemBottom .userList .user {
  width: 52px;
  margin: 0 4px;
  margin-right: 37px
}

.VideoListContentItemBottom .userList .user .userTop {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.VideoListContentItemBottom .userList .user .userTop img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.VideoListContentItemBottom .userList .user .userName {
  width: 50px;
  height: 16px;
  margin-top: 12px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.videoCheck {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6) !important;

  background: #000;
  filter: Alpha(opacity=60);

}

.videoBox {
  width: 900px;
  height: 500px;
  position: absolute;
  top: 40%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 1) !important;
  z-index: 999;

}


video {
  width: 100%;
  height: 100%;
  object-fit: fill;
  position: absolute;
  border-radius: 8px;


}

.close {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  bottom: -80px;
  /* background: #fff; */
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 30px;
  font-size: 40px;
  text-align: center;
  color: #fff;
}

.noMore{
  width: 100%;
  height: 400px;
  position: relative;
}
.quexing {

  width: 300px;
  /* height: 300px; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.quexing img {
  width: 300px;
  /* height: 400px; */
  /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); */

}

.text {
  width: 300px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 88px;
  text-align: center;
}
</style>