<template>
  <div class="box">
    <div class="backgroundMusic">
      <div class="title">
        <span class="titleName">
          背景音乐

        </span>
        <el-button type="primary" @click="btnChange(2)">选择音频</el-button>
        <input  type="file" value="选择图片" ref="file" @change="fileChange($event)" accept="audio/*" style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0"/>
      </div>
      <div class="backgroundMusicList">

        <div class="backgroundMusicItem" v-for="item in music"  :key="item.id"><span class="name">{{item.name}}</span><i class="el-icon-circle-close" @click="DeleteMedia(2,item)"></i></div>

      </div>
   
      <div class="partingLine">

      </div>
    </div>
    <div class="backgroundMusic">
      <div class="title">
        <span class="titleName">
          导购音乐

        </span>
        <el-button type="primary" @click="btnChange(1)">选择音频</el-button>
      </div>
      <div class="backgroundMusicList">

        <div class="backgroundMusicItem" v-for="item in shoppingGuideVoice" :key="item.id" ><span class="name">{{item.name}}</span><i class="el-icon-circle-close" @click="DeleteMedia(1,item)"></i></div>

      </div>
   
      <div class="partingLine">

      </div>
    </div>





  </div>
</template>

<script>

import { OssManager } from "../../../threejs/network/OssManager";


 import {MainObjects} from "../../../threejs/common/MainObjects";
export default {
  data(){
    return{
      // 导购语音
      shoppingGuideVoice:[
        
     
      ],
      // 背景音乐
      music:[

  
      ],
      reNum:"",
       // img地址
      imageUrl:"",
      upType:"",

    }
  },
  mounted(){
    this.reNum = this.getNum(20); //生成图片时 造随机数
  },
  methods:{
    getNum(num) {
      var random = Math.floor(
        (Math.random() + Math.floor(Math.random() * 9 + 1)) *
          Math.pow(10, num - 1)
      );
      return random;
    },
    fileChange(e){
      var _this = this;
        
      let https = MainObjects.Network.m_http;
      const files = e.target.files
      console.log(files,"files")

      let data = new OssManager(MainObjects.Network.m_bucketName);

      let url =   data.getCloudShopFloder("basicInformation", this.reNum)


      let urls = https + url + files[0].name;


      function uploadFinish(list) {
        let urls = https + url + files[0].name;


        this.imageUrl = urls;

       
        let data = {
          id:_this.imageUrl,
          name:files[0].name,
          url:urls
        } 
        if (_this.upType==1) {
          _this.shoppingGuideVoice.push(data)
          
        }else if(_this.upType==2){

          _this.music.push(data)


        }


      }



      this.filestate = false;

      data.f_finishCallback = uploadFinish;
      data.upload(files[0],url)

    },
    DeleteMedia(type,item){
    if (type==2) {
 
        
        this.music.splice( this.music.indexOf(item), 1)

    }else if(type==1){
      this.shoppingGuideVoice.splice(this.shoppingGuideVoice.indexOf(item), 1)

    }
  },
    btnChange(upType){
      this.upType=upType
      if((upType==1&&this.shoppingGuideVoice.length<5)||(upType==2&&this.music.length<5)){
        this.$refs.file.click()

      }else{
        this.$message("每个模块最多上传4个音频");
      }
    },
  },


}
</script>

<style lang="less" scoped>
.box {
  width: 90%;
  margin: 0 auto;
  .backgroundMusic {
    margin-top: 20px;
    height: 280px;
    .title{
          font-size: 18px;
            margin-top: 20px;
            color: #000;
            .titleName{
              margin-right: 10px;
            }
            
      
    }
    .backgroundMusicList{
      height: 150px;
      width: 80%;
      margin: 0 auto;
      margin-top: 20px;
      overflow-y:auto ;

      .backgroundMusicItem{
        width: 100%;
        height: 30px;
               margin-top: 20px;
      line-height: 20px;

        .name{
          display: inline-block;
          overflow: hidden;
          width: 150px;
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap;
          margin-right: 5px;
   

        }
        i{
          float: right;
        }
      }
    }
    .partingLine {

      margin-top:50px;
      height: 2px;
      background-color: rgb(79, 80, 82);
    }
  }
}
</style>