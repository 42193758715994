<template>
  <div class="box">
    <div class="BasicSettingTop">
      <div class="title">店铺归属：{{ vestIn }}</div>

      <div class="topContent">
        <div class="contentItem">
          <div class="shopName">
            <div class="label">
              <span style="color: #f01d1d">*</span> 设置店铺名称:
            </div>
            <input v-model="shopName"  placeholder="请输入店铺名称"    maxlength="20"  type="text" class="name" />
          </div>
          <div class="shopType">
            <div class="label">
              <span style="color: #f01d1d">*</span> 设置店铺类型:
            </div>
            <el-select v-model="shopType" placeholder="请选择店铺类型">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="shopTime">
            <div class="label">
              <span style="color: #f01d1d">*</span> 设置营业时间:
            </div>
            <!-- <el-date-picker
              v-model="openingHours"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
            >
            </el-date-picker> -->
            <div class="time">
              <div>
                <el-time-picker
              is-range
              value-format="timestamp"
              v-model="openingHours"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围">
            </el-time-picker>
              </div>

                
                <!-- <el-time-select
                  placeholder="起始时间"
                  v-model="startTime"
                  value-format="timestamp"
                >
                </el-time-select>
    
             
                <el-time-select
                  placeholder="结束时间"
                  v-model="endTime"
                  value-format="timestamp"
                >
                </el-time-select> -->
             
            </div>
          </div>
        </div>
        <div class="contentItemBottom">
          <div class="introduce">
            <div class="label">
              <span style="color: #f01d1d">*</span> 店铺介绍:
            </div>
            <el-input
              type="textarea"
              :rows="6"
              placeholder="店铺介绍"
              v-model="introduce"
              class="mult"
              resize='none'
              maxlength="100"
            show-word-limit
            >
            </el-input>
          </div>

          <div class="location">
            <div class="label">
              <span style="color: #f01d1d"> *</span> 地址信息:
            </div>
            <input v-model="address"
            maxlength="50"
            
            placeholder="请输入地址信息" type="text" class="locationName" />

            <!-- <div class="MapPlugin">关联地图</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="BasicSettingBottom">
      <div class="BasicSettingBottomTop">
        <div class="ichnography">
          <div class="label"><span></span> 3d可交互场景首页:</div>

          <div>
            <el-switch
              v-model="ichnography"
              active-color="#0560FD"
              inactive-color="#C7C7C7"
              @change="change1()"
            >
            </el-switch>
            <input
              type="file"
              value="选择图片"
              ref="file"
              @change="fileChange($event)"
              accept="image/png, image/jpeg, image/jpg"
              style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0"
            />
            
            <div style="marginTop: 1.30208vw">
              

              <div v-if="ichnography" class="btn" @click="ichnographyChange()">
                
               
                <span v-if="!ichnographyShow">
                  上传平面图
                </span>
                <span v-else >
                  替换
                </span>
                
              </div>
              <img
              v-if="(ichnographyShow&&ichnography)"
                :src="ichnographyUrl"
                style="width: 6.51042vw; hight:6.51042vw; marginTop: 1.30208vw;"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="result">

          <div class="BasicSettingbackground">
          <div class="label"><span></span> 设置店铺背景音:</div>
          <div>

     
          <input
            type="file"
            value="选择图片"
            ref="file2"
            @change="fileChange2($event)"
            accept="audio/*"
            style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0"
          />
          <el-switch
            v-model="backgroundMusic"
            active-color="#0560FD"
            inactive-color="#C7C7C7"
            @change="change3()"
          >
          </el-switch>

          <div style="marginTop: 1.30208vw">
            <div v-if="backgroundMusic" class="btn" @click="backgroundMusicChange()">
                
               
                <span v-if="!backgroundMusicShow">
                  上传店铺背景音
                </span>
                <span v-else >
                  替换
                </span>
                
              </div>


            <div 
            v-if="(backgroundMusicShow&&backgroundMusic)"
            class="backgroundMusicItem">
              <span class="name">{{ backgroundMusicUrl.name }}</span>

              <!-- <i class="el-icon-circle-close" @click="backgroundMusicChange(2,item)"></i> -->
            </div>
          </div>
        </div>
        </div>
        <input
            type="file"
            value="选择图片"
            ref="file3"
            @change="fileChange3($event)"
            accept="audio/*"
            style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0"
          />


        </div>
      </div>
      <input
              type="file"
              value="选择图片"
              ref="file5"
              @change="fileChange5($event)"
              accept="image/png, image/jpeg, image/jpg"
              style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0"
            />

    </div>
  </div>
</template>

<script>
import { OssManager } from "../../../threejs/network/OssManager";

import { MainObjects } from "../../../threejs/common/MainObjects";
export default {
  data() {
    return {
      vestIn: "",
      //店铺名称
      shopName: "",
      //店铺类型
      shopType: "",
      //营业时间
      openingHours:null,
      //店铺介绍
      introduce: "",
      Time: [],

      // 平面图
      ichnography: false,
      ichnographyUrl: "",
      ichnographyShow:false,
      ichnographySize:0,


      //地址
      address: "",

      //效果
      effect: false,
      effectShow:false,

      effectList: [],

      //要替换的图片
      replaceIndex:-1,
      // 背景音乐
      backgroundMusic: false,
      backgroundMusicUrl: {},
      
      backgroundMusicShow:false,
      shoppingMusic: false,

      shoppingMusicShow:false,
      shoppingMusicUrl: {},
      options: [
        {
          value: 0,
          label: "中岛店铺",
        },
        {
          value: 1,
          label: "边厅店铺",
        },
        {
          value: 2,
          label: "店中店",
        },
        {
          value: 3,
          label: "街店",
        },
      ],
      value: "",
      value2: "",
      textarea: "",
      value3: true,
      num: "",

      listShow:false,
    };
  },
  mounted() {
    this.reNum = this.getNum(20);

    if(this.$route.query.affiliation){
      this.vestIn=  this.$route.query.affiliation

    }
  },
  methods: {

    changeAmplification(){
      this.listShow=!this.listShow
    },
    change1(){


        if(!this.ichnography){
          this.$confirm("您确定关闭次功能吗？一旦关闭，之前设置的数据将会自动清除，请谨慎操作", "温情提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                 
          this.ichnographyUrl=""
          this.ichnographyShow=false
  

                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消"
                    });
                    this.ichnography=true
                });




        }
    },

    change2(){
        if(!this.effect){


          this.$confirm("您确定关闭次功能吗？一旦关闭，之前设置的数据将会自动清除，请谨慎操作", "温情提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                 
                  this.effectList=[]
            this.effectShow=false
  

                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消"
                    });
            this.effect=true

                });


        }
    },

    change3(){
      if(!this.backgroundMusic){
        this.$confirm("您确定关闭次功能吗？一旦关闭，之前设置的数据将会自动清除，请谨慎操作", "温情提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                 
                  this.backgroundMusicUrl={}

                  this.backgroundMusicShow=false
  

                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消"
                    });
                  this.backgroundMusic=true

                });

      }
      
    },
    change4(){
      if(!this.shoppingMusic){
        this.$confirm("您确定关闭次功能吗？一旦关闭，之前设置的数据将会自动清除，请谨慎操作", "温情提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                 
                  this.shoppingMusicUrl={}
                this.shoppingMusicShow=false
  

                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消"
                    });
            
                this.shoppingMusic=true

                });
    
      }
      
    },

    deletePicture(item){



      this.effectList.splice( this.effectList.indexOf(item), 1)
    },



    changePicture(item){

      this.$refs.file5.click();

      this.replaceIndex=this.effectList.indexOf(item)

   
    },

    fileChange5(e,) {
      var _this = this;

      let https = MainObjects.Network.m_http;

      //   let getHtttps=MainObjects.Network.m_http;

      const files = e.target.files;
      console.log(files, "files");

      let data = new OssManager(MainObjects.Network.m_bucketName);

      let url = data.getCloudShopFloder("planeGraph", this.reNum);

   
      function uploadFinish(list) {
        let index=_this.replaceIndex
        console.log(index+0,"index");
        _this.effectList[index]= list[0]

        _this.$forceUpdate()
          // item = list[0];

        // _this.filestate = false;
        _this.$refs.file5.value = ""
      }
      data.f_finishCallback = uploadFinish;
      data.upload(files[0], url);
    },



    getNum(num) {
      var random = Math.floor(
        (Math.random() + Math.floor(Math.random() * 9 + 1)) *
          Math.pow(10, num - 1)
      );
      return random;
    },
    fileChange(e) {
      var _this = this;

      let https = MainObjects.Network.m_http;

      //   let getHtttps=MainObjects.Network.m_http;

      const files = e.target.files;
      console.log(files, "files");

      let data = new OssManager(MainObjects.Network.m_bucketName);

      let url = data.getCloudShopFloder("planeGraph", this.reNum);

   
      function uploadFinish(list) {
        let urls = https + url + files[0].name;
        _this.ichnographyUrl = list[0].url.replace(/(\?|#)[^'"]*/, '');

        // _this.filestate = false;
        console.log(list,"list")
        _this.ichnographySize=list[0].size
        _this.ichnographyShow=true

       
      }
      data.f_finishCallback = uploadFinish;
      data.upload(files[0], url);
    },

    ichnographyChange() {
    




              this.$refs.file.click();

      
    },
    fileChange2(e) {
      var _this = this;

      let https = MainObjects.Network.m_http;

      //   let getHtttps=MainObjects.Network.m_http;

      const files = e.target.files;
      console.log(files, "files");

      let data = new OssManager(MainObjects.Network.m_bucketName);

      let url = data.getCloudShopFloder("backgroundMusic", this.reNum);

      function uploadFinish(list) {
        let urls = https + url + files[0].name;

        // _this.filestate = false;

        let data = {
          name: files[0].name,
          url: list[0].url.replace(/(\?|#)[^'"]*/, ''),
          size: list[0].size,
        };

        _this.backgroundMusicUrl = data;
      }
      data.f_finishCallback = uploadFinish;

      data.upload(files[0], url);
    },

    backgroundMusicChange() {


      this.backgroundMusicShow=true

        this.$refs.file2.click();
      
    },

    fileChange3(e) {
      var _this = this;

      let https = MainObjects.Network.m_http;

      //   let getHtttps=MainObjects.Network.m_http;

      const files = e.target.files;
      console.log(files, "files");

      let data = new OssManager(MainObjects.Network.m_bucketName);

      let url = data.getCloudShopFloder("shoppingGuide", this.reNum);

      function uploadFinish(list) {
        let urls = https + url + files[0].name;

        // _this.filestate = false;

        let data = {
          name: files[0].name,
          url: list[0].url.replace(/(\?|#)[^'"]*/, ''),
          size:list[0].size,
        };
        console.log(data,"shoppingMusicUrl");
        _this.shoppingMusicUrl = data;
      }
      data.f_finishCallback = uploadFinish;

      data.upload(files[0], url);
    },

    shoppingMusicChange() {
      this.shoppingMusicShow=true
        this.$refs.file3.click();
    },
    resultChinge(){
    if (this.effect == false) {
        this.effectList = [];
        this.reNum = this.getNum(20);
      } else {
        this.$refs.file4.click();

      }
  },

   fileChange4(e) {
      var _this = this;

      let https = MainObjects.Network.m_http;

      //   let getHtttps=MainObjects.Network.m_http;

      const files = e.target.files;
      console.log(files, "files");
      let list=[]








        let data = new OssManager(MainObjects.Network.m_bucketName);
        let  reNum=this.getNum(20)

        let url = data.getCloudShopFloder("shoppingGuide",reNum);

         function uploadFinish(list) {
          // let urls = https + url + files.name;

          // _this.filestate = false;
          // let effectItem = {
          //   id:index,
          //   name: files[index].name,
          //   url: urls + "?time=" + _this.reNum,
          // };

          console.log(list,"effectItem");

          // list.push(effectItem);
        _this.effectList=  [..._this.effectList,...list] 

        _this.listShow=false

        _this.$refs.file4.value = ""


        

        }
        data.f_finishCallback = uploadFinish;

        data.uploadFileList(files, url);
      
     
    },

  },






};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
  // background-color: antiquewhite;
  overflow: hidden;
  .BasicSettingTop {
    background-color: #fff;
    border-radius: 8px;
    width: 98%;
    height: 380px;
    margin: 0 auto;
    margin-top: 20px;
    overflow: hidden;
    .title {
      width: 96%;
      border-left: 5px solid #0560fd;
      margin: 0 auto;
      margin-top: 20px;
      height: 20px;
      padding-left: 10px;
      line-height: 20px;
      font-size: 18px;
      color: #333;
      font-family: PingFang SC;
      font-weight: bold;
    }
    .topContent {
      width: 96%;
      height: 280px;

      margin: 0 auto;
      margin-top: 30px;
      // background-color: #0560fd;
      overflow: hidden;

      input {
        background: #f7f7f7;
        border-radius: 10px;
        border: none;
        outline: none;
        padding-left: 10px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #929292;
        line-height: 56px;
      }

      .name {
        width: 254px;
        height: 40px;
      }
      .contentItem {
        margin-top: 30px;
        height: 100px;

        display: flex;
        justify-content: space-around;
        .shopName {
          display: flex;
 
        }

        .shopTime {
          display: flex;
          .time{
            width: 400px;
            // height: 100px;
            display: flex;
           justify-content: space-between;
            .start{
              width: 200px;
            }
            .end{
              width: 200px;
            }

          }
   
        }
        .shopType {
          display: flex;
        }
        .label {
          width: 120px;
          margin-right: 10px;
          line-height: 40px;
        }
      }
      .contentItemBottom {
        width: 100%;
        height: 140px;

        display: flex;

        .introduce {
          width: 50%;
          display: flex;
          .mult {
            width: 50%;
          }
          .label {
            // width: 150px;
            margin-right: 10px;
            margin-left: 60px;
          line-height: 30px;

          }
        }
        .location {
          width: 50%;
          display: flex;
          .locationName{
            width: 500px;
            height: 40px;
          }
          .label {

            width: 110px;
            margin-right: 10px;
            margin-left: 40px;
          line-height: 45px;



          }
          .MapPlugin {
            width: 200px;
            height: 50px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 50px;
            text-align: center;
            background: #0560fd;
            border-radius: 10px;
            margin-left: 30px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .BasicSettingBottom {
    background-color: #fff;
    border-radius: 8px;
    width: 98%;
    height: 480px;
    margin: 0 auto;
    margin-top: 20px;
    position: relative;
    overflow: hidden;



    .BasicSettingBottomTop {
      margin-top: 50px;

      width: 50%;
      height: 200px;
      display: flex;

      .BasicSettingbackground {
        width: 50%;
        display: flex;
        .label {
          // width: 150px;
          margin-right: 0px;
          margin-left: 0px;
          line-height: 30px;
        
        }
      }

      .ichnography {
        width: 50%;
        display: flex;
        .label {
          // width: 150px;
          margin-right: 10px;
          margin-left: 90px;
          line-height: 20px;
        }
        img{
          width: 100px;
          height: 100px;
        }

      }

      .result {
        width: 50%;
        display: flex;
        .label {
          // width: 200px;
          margin-right: 10px;
          margin-left: 10px;
          line-height: 20px;

        }
        .uploadingBtn {
          width: 150px;
          height: 40px;
          line-height: 15px;
      
        }
        .enlargement{
          width: 15px;
          height: 15px;
          // background-color: #ff7502;
          float: right;
          // margin-top: 15px;
          margin-left: 10px;
          cursor: pointer;
          line-height: 45px;
          img{
              
              width: 100%;
              height: 100%;

            }
        }
      }
    }
    .BasicSettingBottomBottom {
      margin-top: 50px;

      width: 50%;
      height: 200px;
      display: flex;
      .BasicSettingbackground {
        width: 50%;
        display: flex;
        .label {
          // width: 150px;
          margin-right: 10px;
          margin-left: 90px;
          line-height: 30px;
        
        }
      }
      .guide {
        width: 50%;
        display: flex;
        .label {
          // width: 200px;
          margin-right: 10px;
          margin-left: 40px;
          line-height: 20px;

        }
      }
    }
    .uploadList {
      width: 45%;
      height: 100%;

      position: absolute;
      top: 0;
      right: 0;
      background-color: #f7f7f7;

      box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
      .uploadListTitle {
        width: 100%;
        height: 50px;
        line-height: 50px;
        display: flex;
        box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
        border-radius: 10px 10px 0px 0px;
        background: #ffffff;
        .serial {
          width: 20%;
          text-align: center;
        }
        .uploadImg {
          width: 50%;
          text-align: center;
        }
        .operate {
          width: 20%;
          text-align: center;

          .lessen{
            width: 20px;
            height: 20px;
            float: right;
            // background-color: #ff7502;
            margin-top: 5px;
            margin-right: -60px;
            cursor: pointer;
            img{
              
              width: 100%;

            }
          }

        }
      }

      .content::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      /* 滚动条的滑块 */
      .content::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 5px #69a0fe;
        background: #69a0fe;
        border-radius: 3px;
      }
      .content {
        overflow-y: auto;
        width: 100%;
        height: 65%;

        .uploadListItem {
          margin-top: 10px;
          width: 100%;
          height: 100px;
          display: flex;
          line-height: 100px;
          .serial {
            width: 20%;
            text-align: center;
          }
          .uploadImg {
            width: 50%;
            text-align: center;
            img {
              width: 100px;
              height: 100px;
              border-radius: 8px;
            }
          }
          .operate {
            width: 20%;
            text-align: center;
            display: flex;
            .change {
              width: 50%;
              text-align: center;
              cursor: pointer;
              color: #0560fd;
            }
            .delete {
              width: 50%;
              text-align: center;
              color: #ff7502;
              cursor: pointer;
            }
          }
        }
      }
      .goOn {
        width: 150px;
        height: 40px;
        background: #0560fd;
        border-radius: 10px;
        color: #fff;
        margin: 0 auto;
        margin-top: 20px;

        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.backgroundMusicItem {
  width: 100%;
  height: 30px;
  margin-top: 20px;
  line-height: 20px;

  .name {
    display: inline-block;
    overflow: hidden;
    width: 150px;
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap;
    margin-right: 5px;
  }
  i {
    float: right;
  }
}
.btn{
  width: 150px;
        height: 40px;
        background: #0560fd;
        border-radius: 10px;
        color: #fff;
        margin: 0 auto;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
}
</style>