<template>
  <div class="box">
    <div class="quexing">
        <img  src="../../assets/OfficialWebsite/quexing.png" alt="">
        <div class="text">
          此版块正在开发ing
        </div>

    </div>


  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .box{
    width: 100%;
    height: 800px;
    background-color: #fff;
  position: relative;
  }
  .quexing{
    width: 400px;
    /* height: 300px; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

  }
  .quexing
  img
  {
    width: 500px; 
    /* height: 400px; */
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); */

  }

  .text{
    width: 450px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 88px;
    text-align: center;
  }

</style>