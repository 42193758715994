<template>

    <!-- <div class="box"> -->
    <!-- <div class="conter" v-show="false">
            <div>
                IP:{{ IP }}
                城市：{{ city }}
            </div>
            <div>
                用户手机品牌:{{ mobilePhoneName }}

                用户手机型号：{{modelNumber}}

            </div>
            <div>


                浏览器内核：{{ browserCentre }}
                运行设备：{{ PC }}
            </div>

        </div> -->

    <!-- <div class="iconfile-box">
            <div style="position:absolute;z-index:10;border:undefined solid black">
            <div class="mapIconPattern" v-for="(item,index) in fileData" :key="index" 
            @contextmenu.prevent="$easycm($event,$root)"
            :tabindex="index"
            onselect="return false"
            onselectstart='return false'
            ondragstart="return false"
            @mouseup="selecteItem(item,index)" :title="getTitle(item)"
            @dblclick="ondblclicks(item)"
            :name="file" :id="item.fileId"
            
            
            
            >
            <div class="btn-box" v-show="isShow"></div>


       
            </div>
            
            </div>
        </div> -->



    <!-- </div> -->
    <!-- :style="'width:' + mask_width + ';left:' + mask_left + ';height:' + mask_height + ';top:' + mask_top+';'" -->
    <div class="box" @mousedown="handleMouseDown">
        <div class="mask" v-show="is_show_mask"
          
            
            
            :style="{'width':mask_width+'px','height':mask_height+'px','left':mask_left+'px','top':mask_top+'px'}"            
            
            >
        </div>
        <el-checkbox-group v-model="checkList">
            <el-checkbox v-for="city in cities" :label="city" :key="city">{{city}}</el-checkbox>
            
        </el-checkbox-group>
    </div>




</template>

<script>
import DeviceDetector from "device-detector-js";
export default {
    data() {
        return {

            cities:[
            "测试1",
            ],
            //用户ip
            IP: "",
            // 城市
            city: "",


            // 用户手机名
            mobilePhoneName: "",
            //用户手机型号
            modelNumber: "",

            //浏览器内核
            browserCentre: "",
            //pc还是手机端
            PC: "",


            fileData: [],
            is_show_mask: true,
            mask_width: 0,
            mask_left: 0,
            mask_height: 0,
            mask_top: 0,
            checkList: [],

            end_x: 0,
            end_y: 0,
            end_x:0,
            end_y:0,

        }
    },
    mounted() {

        //获取用户ip
        // this.ready();
        //获取用户设备信息
        this.mobileInfoInit()


        this.IsPC()

    },
    methods: {
        handleMouseDown(event) {
            this.start_x = event.clientX;
            this.start_y = event.clientY;
            this.mask_top=this.start_y;
            this.mask_left=this.start_x;


            // console.log(this.start_x,this.start_y,"this.start_y")
            document.body.addEventListener('mousemove', this.handleMouseMove);
            document.body.addEventListener('mouseup', this.handleMouseUp);
        },



        handleMouseMove(event) {
            this.end_x = event.clientX;
            this.end_y = event.clientY;
             
            // this.mask_width= Math.abs(this.start_x- this.end_x)

            // this.mask_top=this.start_y-this.end_y;
            // this.mask_left=this.start_x- this.end_x;

            let my_width=this.start_x- this.end_x

            this.mask_width= Math.abs(my_width)

            this.mask_height= Math.abs(this.start_y- this.end_y)

       


            console.log(this.mask_width, this.mask_height)
        },

        handleMouseUp() {
            document.body.removeEventListener('mousemove', this.handleMouseMove);
            document.body.removeEventListener('mouseup', this.handleMouseUp);
            // this.handleDomSelect();
            // this.resSetXY();
        },
//         handleDomSelect() {
//     const dom_mask: any = window.document.querySelector('.mask');
//     const rect_select = dom_mask.getClientRects()[0];
//     const add_list: Array<number> = [];
//     const del_list: Array<number> = [];
//     document
//       .querySelectorAll('.el-checkbox-group .el-checkbox')
//       .forEach((node, index) => {
//         const rects = node.getClientRects()[0];
//         if (this.collide(rects, rect_select) === true) {
//           if (this.select_list.includes(this.data_list[index].city_id)) {
//             del_list.push(this.data_list[index].city_id);
//           } else {
//             add_list.push(this.data_list[index].city_id);
//           }
//         }
//       });
//     this.select_list = this.select_list
//       .concat(add_list)
//       .filter((item: number) => !del_list.includes(item));
//   }
//   // eslint-disable-next-line class-methods-use-this
//   collide(rect1: any, rect2: any): boolean {
//     const maxX: number = Math.max(rect1.x + rect1.width, rect2.x + rect2.width);
//     const maxY: number = Math.max(
//       rect1.y + rect1.height,
//       rect2.y + rect2.height,
//     );
//     const minX: number = Math.min(rect1.x, rect2.x);
//     const minY: number = Math.min(rect1.y, rect2.y);
//     if (
//       maxX - minX <= rect1.width + rect2.width &&
//       maxY - minY <= rect1.height + rect2.height
//     ) {
//       return true;
//     } else {
//       return false;
//     }
//   },

        collide(rect1, rect2) {
            const maxX = Math.max(rect1.x + rect1.width, rect2.x + rect2.width);
            const maxY = Math.max(rect1.y + rect1.height, rect2.y + rect2.height);
            const minX = Math.min(rect1.x, rect2.x);
            const minY = Math.min(rect1.y, rect2.y);
            if (maxX - minX <= rect1.width + rect2.width && maxY - minY <= rect1.height + rect2.height) {
                return true;
            } else {
                return false;
            }
        },
        resSetXY() {
    this.start_x = 0;
    this.start_y = 0;
    this.end_x = 0;
    this.end_y = 0;
  },



        ready() {
            var cip = returnCitySN["cip"];
            this.IP = cip;
            this.city = returnCitySN["cname"];
            console.log(returnCitySN, "returnCitySN");

        },
        mobileInfoInit() {
            // 设备信息
            const deviceDetector = new DeviceDetector();
            const userAgent = window.navigator.userAgent;
            let device = deviceDetector.parse(userAgent);
            console.log(device, userAgent)
            this.browserCentre = device.client.name
        },


        IsPC() {
            var is_mobi = navigator.userAgent.toLowerCase().match(/(ipod|ipad|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i) != null;
            if (!is_mobi) {
                console.log("pc");
                this.PC = "PC"
            } else {

                var sUserAgent = navigator.userAgent.toLowerCase()
                var isIphone = sUserAgent.match(/iphone/i) == "iphone";
                var isHuawei = sUserAgent.match(/huawei/i) == "huawei";
                var isHonor = sUserAgent.match(/honor/i) == "honor";
                var isOppo = sUserAgent.match(/oppo/i) == "oppo";
                var isOppoR15 = sUserAgent.match(/pacm00/i) == "pacm00";
                var isVivo = sUserAgent.match(/vivo/i) == "vivo";
                var isXiaomi = sUserAgent.match(/mi\s/i) == "mi ";
                var isXiaomi2s = sUserAgent.match(/mix\s/i) == "mix ";
                var isRedmi = sUserAgent.match(/redmi/i) == "redmi";
                var isSamsung = sUserAgent.match(/sm-/i) == "sm-";








                var ua = navigator.userAgent.toLowerCase();
                if (/android|adr/gi.test(ua)) {
                    // 安卓
                    console.log("这是安卓");
                    this.PC = "安卓"
                    let m1 = navigator.userAgent.match(/Android.*; ?(.*(?= Build))/)
                    if (m1 && m1.length > 1) {
                        // device = m1[1]    // MI 9
                        this.modelNumber = m1[1]
                    }




                    if (isIphone) {
                        this.mobilePhoneName = 'iphone';
                    } else if (isHuawei || isHonor) {
                        this.mobilePhoneName = 'huawei';
                    } else if (isOppo || isOppoR15) {
                        this.mobilePhoneName = 'oppo';
                    } else if (isVivo) {
                        this.mobilePhoneName = 'vivo';
                    } else if (isXiaomi || isRedmi || isXiaomi2s) {
                        this.mobilePhoneName = 'xiaomi';
                    } else if (isSamsung) {
                        this.mobilePhoneName = 'samsung';
                    } else {
                        this.mobilePhoneName = 'default';
                    }








                } else if (/\(i[^;]+;( U;)? CPU.+Mac OS X/gi.test(ua)) {
                    //苹果
                    console.log("苹果");
                    this.PC = "苹果"

                } else if (/iPad/gi.test(ua)) {
                    //ipad
                    console.log("ipad");
                    this.PC = "ipad"


                } else {
                    this.PC = "其他"

                }
            }




        }



    },


}
</script>

<style lang="less" scoped>
.conter {
    width: 500px;
    height: 200px;
    box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
    margin: 0 auto;
    margin-top: 30px;
    background-color: #fff;
}

.box {
    width: 800px;
    height: 500px;
    margin: 20px auto;
    position: relative;
    overflow: hidden;
    user-select: none;
    // background-color: red;

    .mask {
        position: fixed;
        background: #409eff;
        opacity: 0.4;
        z-index: 999;
    }
}
</style>