import { CameraData } from "./CameraData";

import { ModelData } from "./ModelData";
import { CubeData } from "./factory/CubeData";

import { Dexie } from 'dexie'
import { ColoudSoresINetwork } from "./ColoudSoresINetwork"
import * as THREE from 'three';


class ICloudSores {
    constructor() {
        this.m_cameraDataList = [];
        this.m_modelDataList = [];
        this.m_CubeList=[]
        this.m_cloudSoresId = "";
        this.m_sqlId="",
        this.m_cloudSoresDb = new Dexie("ColoudSoresManager");
        this.m_cloudSoresDb.version(2).stores({
            ColoudSores: "id,value",
            camera: "id,value",
            model: "id,value",
        });

    }
    init(id, cameraList3D, modelLis3D) {

        this.m_cloudSoresId = id
        this.setCameraDataList(cameraList3D, modelLis3D)
    }

    destroyedCloydSores() {
        this.m_cloudSoresDb.ColoudSores.delete(this.m_cloudSoresId)
        this.m_cloudSoresDb.camera.delete(this.m_cloudSoresId)
        this.m_cloudSoresDb.model.delete(this.m_cloudSoresId)
    }
    setCameraDataList(cameraList3D, modelLis3D) {
        for (var data of cameraList3D) {
            var cameraData = new CameraData();
            cameraData.m_id = data.id;
            cameraData.m_name = data.name;
            cameraData.m_FOV = data.FOV;
            cameraData.m_vertical = data.vertical;
            cameraData.m_horizontal = data.horizontal;
            cameraData.m_initialCamera = data.initialCamera;
            cameraData.m_img=data.img
            cameraData.m_pos.x = data.position.x
            cameraData.m_pos.y = data.position.y
            cameraData.m_pos.z = data.position.z
            this.m_cameraDataList.push(cameraData);
        }
 
        for (var data of modelLis3D) {
            var modelData = new ModelData();
            modelData.m_id = data.id;
            modelData.m_name = data.name;
            modelData.m_number = data.number;
            modelData.m_type = data.type;
            modelData.m_img = data.img;
            modelData.m_furnitureData = data.furnitureData;
            modelData.m_commodityData = data.commodityData;
            this.m_modelDataList.push(modelData);
        }

    }
    setCubeList(CubeList){
        console.log(CubeList,"CubeList");
        let CubeDataList=[]
        const material = new THREE.MeshBasicMaterial({
            color: 0x00ff00,
            fog: false,
            toneMapped: false,
        });
        CubeList.forEach((item)=>{
            let cube= new CubeData()
            cube.dataByCreateCube(item,material)
            CubeDataList.push(cube)

        })


        this.m_CubeList=CubeDataList

    }
    save(callback) {
        var saveData = {};
        saveData.id = this.m_cloudSoresId
        var cameraDataJson = [];
        for (var data of this.m_cameraDataList) {
            let obj = {
                id: data.m_id,
                name: data.m_name,
                FOV:data.m_FOV,
                vertical:data.m_vertical,
                horizontal:data.m_horizontal,
                initialCamera:data.m_initialCamera,
                img:data.m_img,
                position:data.m_pos

            }
            cameraDataJson.push( JSON.stringify(obj));
        }
        // cameraDataJson.push(JSON.stringify(saveData));
        cameraDataJson = JSON.stringify(cameraDataJson);
        saveData.cameraDataJson = cameraDataJson;
        let CubeList=[]

        this.m_CubeList.forEach((item)=>{
            let obj={
                size:item.size,
                m_id:item.m_id,
                pos:item.pos,
                rot:item.rot,

            }

            CubeList.push(obj)
        })


        saveData.CubeList=CubeList


        this.cacheCamera(this.id, cameraDataJson);
        var modelDataJson = [];
        for (var data of this.m_modelDataList) {
            let obj = {
                id: data.m_id,
                name: data.m_name,
                number:data.m_number,

                type:data.m_type,
                img:data.m_img,
                furnitureData:data.m_furnitureData,
                commodityData:data.m_commodityData,
            }
            modelDataJson.push(JSON.stringify(obj));
        }
        modelDataJson = JSON.stringify(modelDataJson);

        saveData.modelDataJson = modelDataJson;

        this.cacheModel(this.m_cloudSoresId, modelDataJson)
        saveData = JSON.stringify(saveData);

        ColoudSoresINetwork.uploadJsonToOss(this.m_sqlId, saveData, this.m_sqlId, callback)
    }
   


    cacheCamera(id, json, callback) {
        this.m_cloudSoresDb.camera.add({ id: id, value: json }).then(function () {
            if (callback) {
                callback();

            }
        });
    }
    changeSqlId(id){
        this.m_sqlId=id
    }
    cacheModel(id, json, callback) {
        this.m_cloudSoresDb.model.add({ id: id, value: json }).then(function () {
            if (callback) {
                callback();

            }
        });
    }

    async getCameraInDb(callback) {

        var temp = await this.m_cloudSoresDb.camera.where('id').equals(Saver.m_tempSaveName).first();
        let cameraList = []
        if (temp) {
            temp = JSON.parse(temp);

            for (let data of temp) {
                cameraList.push(JSON.parse(data))
            }
        }
        callback(cameraList)
    }
    async getModelInDb(callback) {
        var temp = await this.m_cloudSoresDb.model.where('id').equals(Saver.m_tempSaveName).first();
        let modelList = []
        if (temp) {
            temp = JSON.parse(temp);

            for (let data of temp) {
                modelList.push(JSON.parse(data))
            }
        }
        callback(modelList)
    }

    downloadData(coloudSoresId, name, callback) {
        ColoudSoresINetwork.downloadJsonToOss(
            this.m_sqlId,
            this.m_sqlId,
            function (data) {
                console.log(data);
                let jsonData = data
                // jsonData = JSON.parse(jsonData) 

                let cameraList = JSON.parse(jsonData.cameraDataJson)
                let modelList = JSON.parse(jsonData.modelDataJson)
                let cameraDataJson = []
                let modelDataJson = []
                cameraList.forEach(element => {
                    cameraDataJson.push(JSON.parse(element))
                });

                modelList.forEach(element => {
                    modelDataJson.push(JSON.parse(element))
                });
                let obj = {
                    id: jsonData.id,
                    modelList: modelDataJson,
                    cameraList: cameraDataJson,
                    CubeList:jsonData.CubeList
                }
                callback(obj)

            }
        )
    }


    getCameraDataList() {
        return this.m_cameraDataList;
    }
    getModelDataList() {
        return this.m_modelDataList;

    }
    getCameraData(id) {
        for (var data of this.m_cameraDataList) {
            if (data.id == id) {
                return data.getCameraData()
            }

        }

    }
    setCameraData(id, data) {
        for (var data of this.m_cameraDataList) {
            if (data.id == id) {
                data.setCameraData(data)
            }

        }
    }
    setInitialCamera(id) {
        for (var data of this.m_cameraDataList) {
            if (data.m_id == id) {
                data.m_initialCamera = true
            } else {
                data.m_initialCamera = false
            }

        }
     
    }
    setFurnitureData(id, data) {
        for (let item of this.m_modelDataList) {
            if (id == item.m_id) {
                item.setFurnitureData(data)
            }
        }
    }
    setCommodityData(id, data) {
        for (let item of this.m_modelDataList) {
            if (id == item.m_id) {
                item.setCommodityData(data)
            }
        }
    }
    clearMark(id) {
        for (let item of this.m_modelDataList) {
            if (id == item.m_id) {
                item.clearMark()
            }
        }
    }
    convertDataToSql(){
        let modelList=[]
        this.m_modelDataList.forEach((item)=>{
            let obj ={
                modelId:item.m_id,
                modelName:item.m_name,
                modelImg:item.m_img,
                modelNumber:item.m_number,
                modelType:item.m_type,
            }
            console.log(item.m_type,"item.m_type==0");
            if(item.m_type==0){
                obj.showName=""
                obj.len=""
                obj.breadth=""
                obj.altitude=""
                obj.guidingPrice=""
                obj.url=""
                obj.showImg=""
                obj.describe=""
            }else if(item.m_type==1){
                obj.showName=item.m_commodityData.showName
                obj.len=""
                obj.breadth=""
                obj.altitude=""
                obj.guidingPrice= item.m_commodityData.guidingPrice
                obj.url=item.m_commodityData.goToUrl
                obj.showImg= item.showImg
                obj.describe= item.m_commodityData.describe
            }else if(item.m_type==2){
                obj.showName=item.m_furnitureData.explicitName
                obj.len=item.m_furnitureData.len
                obj.breadth=item.m_furnitureData.breadth
                obj.altitude=item.m_furnitureData.altitude
                obj.guidingPrice=""
                obj.url=""
                obj.showImg=item.m_furnitureData.showImg
                obj.describe=item.m_furnitureData.describe
            }

            modelList.push(obj)
            console.log(obj,"obj");
        })
        return modelList
    }

    
    convertSqlToData(modeList){
        let modelDataList=[]
        for(let item of modeList){
            let modelData = new ModelData();
            modelData.m_id=item.modelId
            modelData.m_name=item.modelName
            modelData.m_img=item.modelImg
            modelData.m_number=item.modelNumber

            let furnitureData = {
                explicitName: "",
                len: "",
                breadth: "",
                altitude: "",
                describe: "",
                showImg: ""
            }
            let commodityData = {
                showName: "",
                guidingPrice: "",
                describe: "",
                showImg: "",
                goToUrl: "",
            }
            // 0 ： 无 1：商品 2：家具
            this.m_type = 0

            if (item.type==1) {
              
                commodityData.showName=item.showName
                commodityData.guidingPrice=item.guidingPrice
                commodityData.goToUrl=item.url
                commodityData.showImg=item.showImg
                commodityData.describe=item.describe
            }else if (item.type==2) {
                furnitureData.explicitName=item.showName
                furnitureData.len=item.len
                furnitureData.breadth=item.breadth
                furnitureData.altitude=item.altitude
                furnitureData.showImg=item.showImg
                furnitureData.describe=item.describe
            }
            modelData.m_furnitureData=furnitureData

            modelData.m_commodityData=commodityData

            modelDataList.push(modelData)
        }
        this.m_modelDataList=modelDataList
    }

}
export { ICloudSores };
