<template>
  <div class="box">


    <div class="BasicSettingTop">
      <div class="title">
        摄像机缩略图
        <div class="cameraIcon" @click="viewMenuChange()">

          <img src="../../../assets/PlugIn/cloudStore/menu.png" alt="">

          <div class="myMenu" v-show="viewMenu">
            <div class="myMenuItem" @click="setCameraTemplateShow()">设置环境模板</div>
            <div class="myMenuItem" @click="setCameraListShow()">摄像机列表</div>
          </div>

          <div class="cameraList" v-show="cameraListShow">
            <div class="cameraListTitle">
              机位列表
              <div class="close">
                <i class="el-icon-close" @click="cameraListShow = false"></i>
              </div>
            </div>

            <div class="cameraConter">
              <div v-for="item in slideshowList" :key='item.m_id' class="cameraItem">
                <img :src="icon" alt="" />
                <span> {{ item.m_name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="topContent">
        <div class="slideshowLeft" @click="slideshowLeft()">

          <i class="el-icon-arrow-left"></i>

        </div>
        <div class="slideshowContent" ref="slideshowContent">
          <div class="slideshowInterior" ref="slideshowInterior">

            <div v-for="item in slideshowList" :key="item.m_id" @click="selectCamera(item)"
              :class="{ 'slideshowItem': true }">
              <div :class="{ 'showImg': true, 'selectSlideshowItem': item.m_id == nowSelectCamera }">

                <img v-show="item.m_img" class="slideshowImg" :src="item.m_img" alt="">
                <div class="displace" v-show="item.m_img" @click="setImg()">替换</div>
              </div>
              <div v-show="!item.m_img" class="uploading" @click="setImg()">
                <i class="el-icon-plus"></i>
              </div>
              <div class="cameraName">
                {{ item.m_name }}

              </div>
            </div>
          </div>

        </div>




        <div class="slideshowRight" @click="slideshowRight()">
          <i class="el-icon-arrow-right"></i>

        </div>
      </div>
    </div>
    <div class="BasicSettingBottom">
      <div class="BasicSettingBottomLeft">
        <div class="title">摄像机视角</div>
        <div class="cameraDisplay" id="runtime_container" ref="runtime_container"></div>
        <div class="myAffirm" @click="confirm()">确认修改</div>
      </div>
      <div class="BasicSettingBottomRight">
        <div class="title">摄像机属性</div>
        <div class="attribute">
          <div class="attributeName">FOV视角：</div>
          <div class="attributeValue">
            <el-slider @change="fovChange()" v-model="nowSelectData.m_FOV" show-input :min="0" :max="360" :show-tooltip="false"></el-slider>
          </div>
        </div>

        <div class="attribute">
          <div class="attributeName">垂直角度：</div>
          <div class="attributeValue">
            <el-slider @change="fovChange()"  v-model="nowSelectData.m_vertical" show-input :min="0" :max="360"
              :show-tooltip="false"></el-slider>
          </div>
        </div>

        <div class="attribute">
          <div class="attributeName">水平角度：</div>
          <div class="attributeValue">
            <el-slider @change="fovChange()"  v-model="nowSelectData.m_horizontal" show-input :min="0" :max="360"
              :show-tooltip="false"></el-slider>
          </div>
        </div>

        <div class="attribute">
          <div class="attributeName">是否设置为初始相机:</div>
          <div class="initialCamera">
            <el-checkbox v-model="nowSelectData.m_initialCamera" @change="setInitial()"></el-checkbox>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :visible="cameraTemplateShow" width="50%" center :modal="false" title="初始机位环境模板设置" append-to-body
      :before-close="cameraTemplateClose" top="15vh">
      <div class="storeType">
        店铺类型：
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>

      <div class="templateList">
        <div class="templateItem">
          <div class="itemTop">
            <img :src="commodity" alt="" />
          </div>
          <div class="templateName">模板名称</div>
        </div>
      </div>

      <div class="templateBtn" @click="cameraTemplateOk()">确认</div>
    </el-dialog>
    <input type="file" value="选择图片" ref="file" @change="fileChange($event)" accept="image/*"
      style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0;margin: 0; padding: 0;" />
  </div>
</template>
  
<script>
import icon from "../../../assets/PlugIn/cloudStore/basics.png";
import commodity from "../../../assets/PlugIn/cloudStore/commodity.png";
import { OssManager } from "../../../threejs/network/OssManager";

import { MainObjects } from "../../../threejs/common/MainObjects";
import { ILoader, IAnimation, IScene, IRender, ITween, ISetting, ICameraControls } from "yi_creator_runtime";

import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

export default {
  data() {
    return {
      // slideshowList: [
      // ],
      options: [
        {
          value: "1",
          label: "类型1",
        },
        {
          value: "2",
          label: "类型二",
        },
        {
          value: "3",
          label: "类型三",
        },
        {
          value: "4",
          label: "钝角",
        },
      ],
      value: "",
      checked: "",
      icon: icon,
      commodity: commodity,
      rot1: 0,
      rot2: 0,
      rot3: 0,
      //视角菜单
      viewMenu: false,
      //相机列表显示
      cameraListShow: false,
      // 相机模板显示
      cameraTemplateShow: false,

      // 当前选中id
      nowSelectCamera: "",
      nowSelectData: {},
      plain: 1,

      control:null,
    };
  },
  props: ["slideshowList"],
  mounted() {



    // this.$refs.slideshowInterior.style.left=0

    let len = this.slideshowList.length;
    let num = len / 3;
    // console.log(this.slideshowList, "this.slideshowList");
    let myNum = Math.ceil(num);

    this.$refs.slideshowInterior.style.width = 440 * 3 * myNum + "px";



    window.addEventListener("click", e => {
      // this.viewMenu=false

    });
    this.nowSelectCamera = this.slideshowList[0].m_id
    this.nowSelectData = this.slideshowList[0]


    setTimeout(() => {
      this.init()

    },0);

  },

  methods: {
    fovChange(){
      this.setThreeCamera(this.nowSelectData)
    },

    setThreeCamera(data) {
      let nowCamera = IScene.getCurrentCamera()
      // let container = document.getElementById("runtime_container");
      let nowSelectData = data


      let control = this.control.m_controls

     
      let pi=Math.PI
      control.minPolarAngle =  (pi/180)* nowSelectData.m_vertical;
      control.maxPolarAngle = (pi/180)* nowSelectData.m_vertical;
      control.minAzimuthAngle =(pi/180)* nowSelectData.m_horizontal;
      control.maxAzimuthAngle = (pi/180)* nowSelectData.m_horizontal;
      // control.enableRotate = false; //禁止旋转
      control.enablePan = false;//禁止平移
      control.enableZoom = false;//禁止缩放
      // control.update()

      // nowCamera.rotateX((pi/180)* nowSelectData.m_vertical) 
      // nowCamera.rotateY((pi/180)* nowSelectData.m_horizontal) 
 

      nowCamera.position.set(nowSelectData.m_pos.x, nowSelectData.m_pos.y, nowSelectData.m_pos.z)
      nowCamera.fov=nowSelectData.m_FOV
      control.update()
      nowCamera.updateProjectionMatrix()

    },
    init() {
      var _this = this
      ISetting.turnOffStats(false);

      var option = {};
      // option.container = document.getElementById("runtime_container");

      option.container = this.$refs.runtime_container


      

      console.log(option.container.offsetWidth,"option.container")

      IRender.initRender(option);
      let control= ICameraControls.createCameraOrbite(true);

      this.control=control

     
      



      this.setThreeCamera(this.nowSelectData)




      let scene= IScene.getScene()

      for (let data of _this.$parent.ICloudSoresThree.m_CubeList) {
        IScene.addToScene(data.cube)
      }
    },

    setImg() {
      this.$refs.file.click();
    },
    // 上传图片
    fileChange(e) {
      var _this = this;

      let https = MainObjects.Network.m_http;

      //   let getHtttps=MainObjects.Network.m_http;
      const files = e.target.files;

      let data = new OssManager(MainObjects.Network.m_bucketName);

      let url = data.getCloudShopFloder("viewingAngle", this.nowSelectCamera);
      function uploadFinish(list) {

        _this.nowSelectData.m_img = list[0].url.replace(/(\?|#)[^'"]*/, '');
        _this.$parent.ICloudSoresThree.setCameraData(this.nowSelectCamera, this.nowSelectData)
        _this.$parent.getData()


      }
      data.f_finishCallback = uploadFinish;
      data.upload(files[0], url);
    },
    // 设置初始相机 
    setInitial() {
      this.$confirm('您确定要将摄像机设置为初始相机吗？请注意，一旦确定用户登录店铺链接后，首次进入时就会出现在初始位置，店铺尽可有一个初始相机', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$parent.ICloudSoresThree.setInitialCamera(this.nowSelectCamera)
        this.$parent.getData()
        this.$message({
          type: 'success',
          message: '成功!'
        });
      }).catch(() => {
        this.nowSelectData.m_initialCamera = false
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    confirm() {
      this.$confirm('此操作将修改数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$parent.ICloudSoresThree.setCameraData(this.nowSelectCamera, this.nowSelectData)
        this.$parent.getData()
        this.$message({
          type: 'success',
          message: '成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });



    },
    selectCamera(item) {
      this.nowSelectCamera = item.m_id;
      this.nowSelectData = item;
      this.setThreeCamera(this.nowSelectData)


    },


    setCameraTemplateShow() {
      this.cameraTemplateShow = true;

      this.viewMenu = false;

    },
    cameraTemplateClose() {
      this.cameraTemplateShow = false;
      this.viewMenu = false;
    },

    cameraTemplateOk() {
      this.cameraTemplateShow = false;
      this.viewMenu = false;

    },

    setCameraListShow() {
      this.cameraListShow = true;
      this.viewMenu = false;
    },








    viewMenuChange() {
      console.log("11111")
      this.viewMenu = !this.viewMenu

    },




    downloadClose() {

    },
    slideshowLeft() {
      let slideshowInterior = this.$refs.slideshowInterior;

      let left = Number(slideshowInterior.style.left.slice(0, -2));
      let len = this.slideshowList.length;

      let num = len / 3;

      let myNum = Math.ceil(num) - 1;
      console.log(myNum, "myNum");

      console.log(left, "left", myNum);

      let moveLeft = Math.ceil(this.$refs.slideshowContent.offsetWidth);



      if (left == 0) {
        slideshowInterior.style.left = -(moveLeft * myNum) + "px";

        // console.log(this.$refs.slideshowContent.offsetWidth, myNum ,"moveLeft  myNum")
      } else {
        slideshowInterior.style.left = left + moveLeft + "px";
      }
    },
    slideshowRight() {
      let slideshowInterior = this.$refs.slideshowInterior;

      let left = Number(slideshowInterior.style.left.slice(0, -2));
      let len = this.slideshowList.length;

      let num = len / 3;

      let myNum = Math.ceil(num) - 1;
      let moveLeft = Math.ceil(this.$refs.slideshowContent.offsetWidth);


      if (left == -moveLeft * myNum) {
        slideshowInterior.style.left = 0 + "px";
      } else {
        slideshowInterior.style.left = left - moveLeft + "px";
      }
    },
  },
};
</script>
  
<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
  // background-color: antiquewhite;
  overflow: hidden;

  .BasicSettingTop {
    background-color: #fff;
    border-radius: 8px;
    width: 97%;
    height: 320px;
    margin: 0 auto;
    margin-top: 20px;
    // overflow: hidden;
    border-top: 1px solid #fff;

    .title {
      width: 96%;
      border-left: 5px solid #0560fd;
      margin: 0 auto;
      margin-top: 20px;
      height: 20px;
      padding-left: 10px;
      line-height: 20px;
      font-size: 18px;
      color: #333;
      font-family: PingFang SC;
      font-weight: bold;

      .cameraIcon {
        width: 20px;
        height: 20px;
        // background-color: red;
        float: right;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }

        .myMenu {
          position: absolute;
          width: 158px;
          height: 79px;
          background: #ffffff;
          border-radius: 8px;
          left: -150px;
          top: 20px;
          z-index: 999;

          .myMenuItem {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
          }
        }

        .cameraList {
          position: absolute;
          left: -350px;
          top: 20px;
          z-index: 9999;
          width: 350px;
          height: 300px;
          background: #fff;
          border-radius: 10px;
          box-shadow: 0px 0px 11px 0px rgba(72, 146, 244, 0.15);

          .cameraListTitle {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #000000;
            line-height: 56px;
            text-align: center;

            .close {
              width: 10px;
              height: 10px;
              position: absolute;
              right: 20px;
              top: 0px;
            }
          }

          .cameraConter::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }

          /* 滚动条的滑块 */
          .cameraConter::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 5px #69a0fe;
            // background: #69a0fe;
            border-radius: 3px;
          }

          .cameraConter {
            width: 100%;
            height: 70%;
            margin-top: 10px;
            overflow-y: auto;

            .cameraItem {
              width: 100%;
              height: 40px;
              margin-top: 10px;

              line-height: 40px;
              font-weight: 500;
              color: #333333;
              font-size: 16px;
              display: flex;
              overflow: hidden;
              text-overflow: ellipsis;

              img {
                width: 30px;
                height: 30px;
                margin: 5px;
              }

              span {
                width: 80%;
              }
            }
          }
        }
      }
    }

    .topContent {
      width: 90%;
      height: 230px;

      margin: 0 auto;
      margin-top: 30px;
      // background-color: #0560fd;
      display: flex;
      justify-content: space-between;

      .slideshowLeft {
        width: 90px;
        height: 100%;
        // background-color: blanchedalmond;
        text-align: center;
        font-size: 28px;
        line-height: 200px;

      }

      .slideshowContent {
        width: 1400px;
        height: 100%;
        overflow: hidden;
        // background-color: darkred;
        position: relative;

        .slideshowInterior {
          position: relative;
          height: 100%;
          left: 0;

          .slideshowItem {
            width: 300px;
            height: 100%;
            border-radius: 8px;
            float: left;
            margin: 66px;
            margin-top: 0;
            margin-bottom: 0;
            position: relative;

            .showImg {
              width: 100%;
              height: 85%;
              background: #f0f5ff;
              position: relative;

              .slideshowImg {
                width: 100%;
                height: 100%;

              }

              .displace {
                position: absolute;
                right: 10px;
                bottom: 10px;
                cursor: pointer;
              }

            }

            .selectSlideshowItem {
              box-shadow: 0 0 2px 0 rgb(5 96 253 / 50%);
            }

            .uploading {
              height: 100px;
              width: 100px;
              background: #fff;
              border-radius: 50%;
              position: absolute;
              top: 40%;
              left: 50%;
              transform: translate(-50%, -50%);

              i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 40px;
              }

            }

            .cameraName {
              width: 100%;
              height: 30px;
              text-align: center;
              line-height: 30px;
              position: absolute;
              bottom: 0;

            }
          }
        }
      }

      .slideshowRight {
        width: 90px;
        height: 100%;
        // background-color: blanchedalmond;
        text-align: center;
        font-size: 28px;
        line-height: 200px;
      }
    }
  }

  .BasicSettingBottom {
    // background-color: #fff;
    // border-radius: 8px;
    width: 97%;
    height: 550px;
    margin: 0 auto;
    margin-top: 20px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    .BasicSettingBottomLeft {
      background-color: #fff;
      border-radius: 8px;
      width: 62%;
      height: 100%;
      border-top: 1px solid #fff;

      .title {
        width: 96%;
        border-left: 5px solid #0560fd;
        margin: 0 auto;
        margin-top: 20px;
        height: 20px;
        padding-left: 10px;
        line-height: 20px;
        font-size: 18px;
        color: #333;
        font-family: PingFang SC;
        font-weight: bold;
      }
    }

    .BasicSettingBottomRight {
      background-color: #fff;
      border-radius: 8px;
      width: 35%;
      height: 100%;
      border-top: 1px solid #fff;

      .title {
        width: 90%;
        border-left: 5px solid #0560fd;
        margin: 0 auto;
        margin-top: 20px;
        height: 20px;
        padding-left: 10px;
        line-height: 20px;
        font-size: 18px;
        color: #333;
        font-family: PingFang SC;
        font-weight: bold;
      }

      .attribute {
        width: 90%;
        height: 60px;

        margin: 0 auto;
        margin-top: 20px;

        display: flex;
        justify-content: space-between;

        .attributeName {
          width: 160px;

          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 60px;
          margin-right: 10px;
        }

        .attributeValue {
          width: 310px;
          margin-top: 12px;
        }

        .initialCamera {
          width: 310px;
          margin-top: 23px;
        }
      }
    }

    .cameraDisplay {
      width: 700px;
      height: 350px;
      background: #f0f5ff;
      border-radius: 8px;
      margin: 20px auto;
    }

    .myAffirm {
      width: 160px;
      height: 40px;
      background: #0560fd;
      border-radius: 20px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}

.templateList::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* 滚动条的滑块 */
.templateList::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px #69a0fe;
  background: #69a0fe;
  border-radius: 3px;
}

.templateList {
  width: 100%;
  height: 500px;
  margin-top: 20px;

  overflow-y: auto;

  .templateItem {
    width: 20%;
    height: 200px;
    background-color: #fff;

    margin: 20px;
    float: left;

    .itemTop {
      width: 100%;
      height: 150px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .templateName {
      width: 100%;
      height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 50px;
    }
  }
}

.templateBtn {
  width: 160px;
  height: 40px;
  background: #0560fd;
  border-radius: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
}
</style>